<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>感谢你的报名</h1>
    </TitleModel>
    <div style="padding: 20px; padding-top: 0px;">
        <v-card class="infocard mx-auto">
            <v-img src="../../assets/recruitment/nxq_ybm.jpg"></v-img>
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="text-h5" style="font-weight: bold;">
                        已报名通知群
                    </v-card-title>
                    <v-card-text>扫码加入已报名的通知微信群，群里会及时发布面试等通知，请务必加入！</v-card-text>
                </div>
            </div>
        </v-card>
    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({

    }),
    methods: {

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
}

.infocard {
    max-width: 400px;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>