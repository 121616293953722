<template>
    <v-img class="mainlogo mx-auto" src="../../assets/数协透明.png"></v-img>
    <!-- <div style="text-align: center;">
        <h1 class="mx-auto">本届招新已经结束</h1>
        <h1 class="mx-auto">感谢你的支持，期待下次相遇</h1>
    </div> -->

    <v-form class="mx-auto application-form">

        <TitleModel>
            <h2>基本信息</h2>
        </TitleModel>
        <v-form>
            <v-text-field label="姓名" v-model="formData.name" :rules="[required]"></v-text-field>
            <v-text-field label="手机号" v-model="formData.phone" :rules="[required]"
                hint="将用于通知以及面试信息查询，请务必正确填写能联系到的手机号"></v-text-field>
            <v-select label="所在院系" v-model="formData.department"
                :items="['航空港管理学院', '民航电子信息工程学院', '民航经营管理学院', '飞机维修工程学院', '无人机学院', '空乘学院']" :rules="[required]"></v-select>
            <v-text-field label="所在班级" v-model="formData.class" :rules="[required]"></v-text-field>
            <v-text-field label="微信号" v-model="formData.wxid" :rules="[required]"></v-text-field>
            <!-- <v-text-field label="高考数学分数及试卷类型" placeholder="如：98 新高考一卷" v-model="formData.gkfs" :rules="[required]"></v-text-field> -->

            <TitleModel>
                <h2>纳新问卷</h2>
            </TitleModel>
            <v-select label="意向部门" v-model="formData.intention" :items="['数学建模部【共建】', '学术部', '宣传部', '秘书部']"
                :rules="[required]" multiple chips hint="按照第一意向、第二意向等进行先后选择" persistent-hint></v-select>

            <v-textarea v-model="formData.awards" label="是否有获得过什么数学相关的奖项或荣誉？"
                placeholder="没有的话填无即可，可以是任何类型的奖项或荣誉，有没有不影响录取，只是为了了解你~" auto-grow></v-textarea>
            <v-textarea v-model="formData.selfIntroduction" label="简短的个人特长和自我介绍" auto-grow></v-textarea>
        </v-form>

        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                :loading="sendloading">提交</v-btn>
        </div>

    </v-form>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {

        TitleModel
    },
    data: () => ({

        sendloading: false,

        formData: {
            name: '',
            phone: '',
            department: null,
            class: '',
            wxid: '',
            gkfs: '',
            intention: [],
            awards: '',
            selfIntroduction: ''
        },
    }),
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        },
        navigateToDestination(url) {
            window.open(url, '_blank');
        },
        send() {
            this.sendloading = true

            axios
                .post('https://api.gcacitia.com/home/recruitment/sendApplicationMA', this.formData)
                .then(response => {
                    this.sendloading = false
                    alert('报名成功')
                    location.reload()
                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.application-form {
    width: 550px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .application-form {
        width: 90vw;
    }
}
</style>