<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>编程部</h1>
    </TitleModel>
    <div class="mx-auto" style="text-align: left; max-width: 1000px; padding-left: 15px; padding-right: 15px;">
        <p>编程部负责协会会员的计算机知识、编程知识培养。在培养方案上具有扩展性，发散性，在教学方式上将实行老带新和项目小组的方式，即由有经验的各组组长带领新生自主选定项目并以某种方式将成品呈现出来。并且为协会竞赛部及项目部培养人才。除此之外还需不定期面向全校师生提供相关知识的普及性讲座。
        </p>
        <v-card class="mx-auto" style="margin-top: 20px;">
            <v-card-title>部长寄语</v-card-title>
            <v-card-subtitle>编程部总负责人 张子彬</v-card-subtitle>
            <v-card-text style="text-align: left;">不必在意别人的眼光，不要忘记自己的初心，以实际行动证明自己。
            </v-card-text>
        </v-card>
    </div>

    <TitleModel>
        <h2>总负责人</h2>
    </TitleModel>

    <div style="padding-left: 15px; padding-right: 15px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/张子彬.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        张子彬
                    </v-card-title>
                    <v-card-subtitle><strong>编程部总负责人</strong></v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>zhangzibin@gcacitia.com</v-card-subtitle>
                    <v-card-text>22级大数据技术1班班长，于2022届加入计算机协会编程部Python组，2023年通过编程部综合考核入选竞赛部Python组，2023届就任互联网科技创新协会编程部总负责人。熟悉多种编程语言和开发工具，主攻领域为计算机软件，针对于Python程序与算法设计、Flask后端开发、Web前端开发。</v-card-text>
                </div>
            </div>
        </v-card>
    </div>

    <TitleModel>
        <h2>编程部组长</h2>
    </TitleModel>

    <div class="infocardbox mx-auto">
        <v-card class="infocard" v-for="(member, i) in membersinfo" :key="i">
            <v-img class="mx-auto" :src="member.photo" style="width: 70%; margin-top: 10%; margin-bottom: 10%;"></v-img>
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="text-h5" style="font-weight: bold;">
                        {{ member.name }}
                    </v-card-title>
                    <v-card-text style="max-width: 220px;">
                        <strong>方向：</strong>{{ member.fx }}
                        <p><strong>简介：</strong>{{ member.info }}</p>
                    </v-card-text>

                </div>
            </div>
        </v-card>


    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({
        membersinfo: [
            {
                name: '罗嘉堡',
                photo: require('../../assets/member/罗嘉堡.png'),
                fx: 'Python程序设计',
                info: '2022届加入编程部C语言组，2023年通过综合考核入选竞赛部Python组。'
            },
            {
                name: '黎幸灵',
                photo: require('../../assets/member/黎幸灵.png'),
                fx: 'Web前端开发',
                info: '2022届加入编程部Web组，2023年通过综合考核入选竞赛部Web组。'
            },
            {
                name: '纪道坤',
                photo: require('../../assets/member/纪道坤.png'),
                fx: 'Web前端开发',
                info: '2022届加入编程部Web组。2023年获得1+X前端开发职业证书。'
            },
            {
                name: '吴天航',
                photo: require('../../assets/member/吴天航.png'),
                fx: 'Python程序设计',
                info: '2022届加入编程部C语言组。'
            },
            {
                name: '黄紫馨',
                photo: require('../../assets/member/黄紫馨.png'),
                fx: 'Python程序设计',
                info: '2022届加入编程部Python组，2023年通过综合考核入选竞赛部Python组。'
            },
            {
                name: '张丹云',
                photo: require('../../assets/member/张丹云.png'),
                fx: 'Python程序设计',
                info: '2022届加入运营部，2023年通过综合考核入选竞赛部Python组。'
            },
            {
                name: '邱培钦',
                photo: require('../../assets/member/邱培钦.png'),
                fx: 'C语言程序设计',
                info: '2022届加入编程部C语言组，2023年通过综合考核入选竞赛部Python组。'
            },
            {
                name: '郑嘉慧',
                photo: require('../../assets/member/郑嘉慧.png'),
                fx: 'Web前端开发',
                info: '2022届加入编程部Web组，2023年通过综合考核入选竞赛部Web组。'
            },
        ]
    }),
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;

}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

}
</style>