<template>
    <v-img class="mainlogo mx-auto" src="../assets/logo_text.png"></v-img>

    <TitleModelDown v-if="showtip">
        <template v-slot:title>
            <h1>提交成功</h1>
        </template>
        <template v-slot:info>
            <h4>请静候我们与您进行联系，您也可以随时在本页面查询报修处理进度</h4>
        </template>
    </TitleModelDown>
    <TitleModel>
        <h1>报修进度查询</h1>
    </TitleModel>
    <v-form class="mx-auto application-form">
        <!-- 表单输入字段 -->
        <v-text-field label="手机号" v-model="phone" :rules="[required]" placeholder="报修所填写的手机号"></v-text-field>

        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                :loading="sendloading">查询</v-btn>
        </div>
    </v-form>
    <v-card elevation="0" :color="item.state == '已拒单' ? '#fde8e6': item.state == '待处理' ? '#fff2e0': item.state == '处理中' ? '#e4f2fd': '#f2fff5'" v-for="item in querydata" class="infocard mx-auto">
        <v-card-text>
            <p><strong>单号</strong> {{ item.id }}</p>
            <p v-if="item.state == '已拒单'" style="color: red;"><strong style="color: black;">工单状态</strong> 已拒单</p>
            <p v-if="item.state == '未处理'" style="color: #ff9b0a;"><strong style="color: black;">工单状态</strong> 待处理</p>
            <p v-if="item.state == '处理中'" style="color: #2a99f2;"><strong style="color: black;">工单状态</strong> 处理中</p>
            <p v-if="item.state == '已完成'" style="color: #5fb864;"><strong style="color: black;">工单状态</strong> 已完成</p>
        </v-card-text>
        <v-card-text>
            <p><strong>姓名</strong> {{ item.name }}</p>
            <p><strong>宿舍</strong> {{ item.dormitory }}</p>
            <p><strong>故障类型</strong> {{ item.questionType }}</p>
            <p><strong>故障描述</strong> {{ item.info }}</p>
        </v-card-text>

    </v-card>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import TitleModelDown from '@/components/TitleModelDownText.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel,
        TitleModelDown
    },
    data: () => ({
        name: '',
        phone: '',
        sex: [],
        dormitory: [],
        department: [],

        type: [],
        brand: [],
        questionType: [],
        info: '',

        showtip: false,

        sendloading: false,

        querydata: []
    }),
    mounted() {
        this.showtip = this.$route.query.showtip == 1
    },
    methods: {
        send() {
            if (this.sendloading) {
                return
            }

            var data = {
                phone: this.phone
            }

            this.sendloading = true
            axios.post('https://api.gcacitia.com/home/repair/getphone', data)
                .then(response => {
                    this.sendloading = false
                    response.data.reverse()
                    this.querydata = response.data.reverse()
                })
                .catch(error => {
                    // 处理错误
                    this.sendloading = false
                    alert('发生错误或无工单记录 ，请联系微信：leoleeym')

                });
        }   

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
}

.infocard {
    max-width: 400px;
}

.infocard {
    width: 800px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

    .infocard {
        width: 90%;
    }

}</style>