<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <div style="text-align: center;">
        <h1 class="mx-auto">2024届招新活动</h1>
    </div>

    <v-form class="mx-auto application-form" style="">
        <!-- 表单输入字段 -->
        <div v-if="!check">
            <TitleModel>
                <h2>扫码登记</h2>
            </TitleModel>
            <v-select label="二维码类型" item-title="state" item-value="abbr" v-model="type"
                :items="[
                    {
                        state: '兑奖',
                        abbr: 'cj'
                    },
                    {
                        state: '华为云主机',
                        abbr: 'hwcloud'
                    },
                    {
                        state: 'CodeLab',
                        abbr: 'codelab'
                    },
                    {
                        state: '技术部',
                        abbr: 'jsb'
                    },
                    {
                        state: '电子部',
                        abbr: 'codelab'
                    },
                    
                ]"></v-select>
            <div style="margin-left: 45px;">
                <div class="qr-code mx-auto" ref="qrCodeUrl"></div>
            </div>

        </div>

    </v-form>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

import QRCode from 'qrcodejs2';

export default defineComponent({
    name: 'Home',
    components: {

        TitleModel
    },
    data: () => ({
        startTime: 0,

        showCodeEdit: false,
        phone: '',
        wxid: '',

        data: null,

        bcb: '',
        yyb: '',
        jsb: '',
        scb: '',
        dzb: '',

        sendloading: false,

        check: false,

        timey: false,
        atyId: '',

        type: 'hwcloud'
    }),
    mounted() {
        let time = this.$route.query.time
        let atyId = this.$route.query.atyId

        this.getcode()

        setInterval(() => {
            this.getcode()
        }, 500)


    },
    methods: {

        getcode() {
            if (this.$refs.qrCodeUrl) {
                // 清空二维码容器
                this.$refs.qrCodeUrl.innerHTML = '';
                // 生成新的二维码
                this.creatQrCode(`http://gcacitia.com/recruitment/activity?time=${Date.now()}&atyId=${this.type}`);
            }
        },
        creatQrCode(url) {
            new QRCode(this.$refs.qrCodeUrl, {
                text: url, // 二维码的内容
                colorDark: '#000',
                colorLight: '#fff',
                correctLevel: QRCode.CorrectLevel.H
            });
        },


        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        },
        navigateToDestination(url) {
            window.open(url, '_blank');
        },
        queryData() {
            this.sendloading = true
            axios.get(`https://api.gcacitia.com/i/recruitment/activity/${this.phone}`)
                .then(res => {
                    this.data = res.data.activity
                    this.sendloading = false
                    this.check = true
                    this.startTime = Math.floor(Date.now() / 1000)

                    if (this.timey) {
                        if (this.atyId != '') {
                            if (this.atyId == 'cj') {
                                let cs = 0
                                if (this.data.hwcloud == 1) {
                                    cs += 1
                                    this.data.hwcloud = 2
                                }
                                if (this.data.dzb == 1) {
                                    cs += 1
                                    this.data.dzb = 2
                                }
                                if (this.data.jsb == 1) {
                                    cs += 1
                                    this.data.jsb = 2
                                }
                                if (this.data.codelab == 1) {
                                    cs += 1
                                    this.data.codelab = 2
                                }
                                if (this.data.vr == 1) {
                                    cs += 1
                                    this.data.vr = 2
                                }

                                axios.put(`https://api.gcacitia.com/i/recruitment/activity/update/${res.data.id}`, {
                                    activity: JSON.stringify(this.data)
                                }).then(res => {
                                    this.getdata()
                                })

                                alert('成功兑换抽奖机会 ' + cs.toString() + ' 次')
                            }
                            else {
                                if (this.data[this.atyId] == 2) {
                                    alert('您已经兑换过该活动')
                                } else {
                                    this.data[this.atyId] = 1
                                    axios.put(`https://api.gcacitia.com/i/recruitment/activity/update/${res.data.id}`, {
                                        activity: JSON.stringify(this.data)
                                    }).then(res => {
                                        this.getdata()
                                    })
                                }

                            }

                        }
                    }

                })
                .catch((error) => {
                    this.sendloading = false
                    alert('未找到报名信息')
                })
        },
        getdata() {
            this.sendloading = true
            axios.get(`https://api.gcacitia.com/i/recruitment/activity/${this.phone}`)
                .then(res => {
                    this.data = res.data.activity
                    this.sendloading = false

                })
                .catch((error) => {
                    this.sendloading = false
                    alert('未找到报名信息')
                })
        },
        send() {
            this.sendloading = true
            const dataToSend = {
                bcb: this.bcb,
                yyb: this.yyb,
                jsb: this.jsb,
                scb: this.scb,
                dzb: this.dzb,
                time: Math.floor(Date.now() / 1000) - this.startTime
            };

            this.sendloading = true
            axios.put(`https://api.gcacitia.com/i/recruitment/application/update/${this.data.id}`, {
                wxid: this.wxid,
                questions: JSON.stringify(dataToSend)
            })
                .then(res => {
                    this.sendloading = false
                    alert('提交成功，准备好面试吧！')
                    this.$router.push('/');
                })
                .catch((error) => {
                    this.sendloading = false
                    alert('提交失败，请联系工作人员')
                })



        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.application-form {
    width: 550px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .application-form {
        width: 90vw;
    }
}
</style>