<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>运营部</h1>
    </TitleModel>

    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <p>运营部负责协会的内外宣传工作、协会活动的后勤、布置工作；运营部新媒体运营方向还需要进行协会新媒体账号、平台的运营和内容生产等。</p>
        <v-card class="mx-auto" style="margin-top: 20px;">
            <v-card-title>部长寄语</v-card-title>
            <v-card-subtitle>运营部负责人 凌佳欣</v-card-subtitle>
            <v-card-text style="text-align: left;">明日永远新鲜如初，纤尘不染。</v-card-text>
            <v-card-subtitle>运营部负责人 宋雨桐</v-card-subtitle>
            <v-card-text style="text-align: left;">你走过的路才是你的世界。</v-card-text>
        </v-card>
    </div>

    <TitleModel>
        <h2>运营部负责人</h2>
    </TitleModel>

    <div style="padding-left: 15px; padding-right: 15px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/黄卓琪.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        黄卓琪
                    </v-card-title>
                    <v-card-subtitle><strong>邮箱：</strong>huangzhuoqi@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2022届加入计算机协会运营部，2023届就任互联网科技创新协会运营部负责人。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/凌佳欣.jpg"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        凌佳欣
                    </v-card-title>
                    <v-card-subtitle><strong>邮箱：</strong>lingjiaxin@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2022届加入计算机协会运营部，2023届就任互联网科技创新协会运营部负责人。为人和善，擅长微信公众号推文制作。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/康国熙.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        康国熙
                    </v-card-title>
                    <v-card-subtitle><strong>邮箱：</strong>kangguoxi@gcacitia.com</v-card-subtitle>
                    <v-card-text>辅导员助理、23级新生助理班主任。于2022届加入计算机协会运营部，2023年通过双创部面试加入互联网科技创新协会双创部，2023届就任互联网科技创新协会运营部负责人。熟练运用Office办公软件，有很强的组织能力。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/宋雨桐.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        宋雨桐
                    </v-card-title>
                    <v-card-subtitle><strong>邮箱：</strong>songyutong@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2022届加入计算机协会运营部，2023届就任互联网科技创新协会运营部负责人。能熟练运用Office Word、Excel、等应用软件，对工作持积极认真的态度。</v-card-text>
                </div>
            </div>
        </v-card>
    </div>

</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({

    }),
    methods: {

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.cardflex {
    display: flex;
    flex-wrap: nowrap;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>