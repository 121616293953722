import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AssetsLib from '../views/AssetsLib.vue'

import Repair from '../views/Repair.vue'
import RepairQuery from '../views/RepairQuery.vue'

import Application from '../views/recruitment/Application.vue'
import ApplicationSuccess from '../views/recruitment/ApplicationSuccess.vue'
import ApplicationExam from '../views/recruitment/ApplicationExam.vue'
import Introduction from '../views/recruitment/Introduction.vue'
import Query from '../views/recruitment/Query.vue'
import AdmissionQuery from '../views/recruitment/AdmissionQuery.vue'

import ApplicationMA from '../views/recruitment/ApplicationMA.vue'

import AboutMeun from '../views/about/AboutMeun.vue'
import Council from '../views/about/Council.vue'
import Program from '../views/about/Program.vue'
import Operation from '../views/about/Operation.vue'
import Technology from '../views/about/Technology.vue'
import Shuangchuang from '../views/about/Shuangchuang.vue'
import DianZi from '../views/about/DianZi.vue'

import Auth from '@/views/background/Auth.vue'
import Manage from '@/views/background/Manage.vue'

import Understand from '@/views/UnderstandView.vue'



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/assetslib',
    name: 'assetslib',
    component: AssetsLib
  },
  {
    path: '/repair',
    name: 'repair',
    component: Repair
  },
  {
    path: '/repairquery',
    name: 'repairquery',
    component: RepairQuery
  },
  {
    path: '/recruitment/applicationma',
    name: 'applicationma',
    component: ApplicationMA
  },
  {
    path: '/recruitment/application',
    name: 'application',
    component: Application
  },
  {
    path: '/recruitment/applicationexam',
    name: 'applicationexam',
    component: ApplicationExam
  },
  {
    path: '/recruitment/success',
    name: 'applicationSuccess',
    component: ApplicationSuccess
  },
  {
    path: '/recruitment/introduction',
    name: 'introduction',
    component: Introduction
  },
  {
    path: '/recruitment/query',
    name: 'query',
    component: Query
  },
  {
    path: '/recruitment/admission',
    name: 'admission',
    component: AdmissionQuery
  },
  {
    path: '/recruitment/activity',
    name: 'activity',
    component: () => import('../views/recruitment/Activity.vue')
  },
  {
    path: '/recruitment/activityqrcodeacshjlajsdcnklscaklbjnasclkascklndklasdklwdqmklqwdklwdqlk',
    name: 'activityQRCode',
    component: () => import('../views/recruitment/ActivityQRCode.vue')
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: () => import('../views/DownloadCenter.vue')
  },

  {
    path: '/about',
    name: 'about',
    component: AboutMeun
  },
  {
    path: '/about/council',
    name: 'council',
    component: Council
  },
  {
    path: '/about/program',
    name: 'program',
    component: Program
  },
  {
    path: '/about/operation',
    name: 'operation',
    component: Operation
  },
  {
    path: '/about/technology',
    name: 'technology',
    component: Technology
  },
  {
    path: '/about/shuangchuang',
    name: 'shuangchuang',
    component: Shuangchuang
  },

  {
    path: '/about/dianzi',
    name: 'dianzi',
    component: DianZi
  },

  {
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  {
    path: '/manage',
    name: 'manage',
    component: Manage
  },
  {
    path: '/understand',
    name: 'understand',
    component: Understand
  },
  {
    path: '/mathmodel',
    name: 'mathmodel',
    component: () => import('../views/ShuChuangMathModel.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach(() => {
  // 在动画完成后滚动到顶部
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 600); // 这里的500是动画持续时间的毫秒数，根据实际情况调整
})

export default router
