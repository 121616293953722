

<template>
  <div class="drop-letter">
    <transition-group name="letters" tag="div">
      <div v-for="(letter, index) in letters" :key="letter.id"
        :style="{ left: `${letter.left}px`, animationDuration: `${letter.duration}s`, color: letter.color, opacity: letter.opacity, fontSize: `${letter.fontSize}px` }"
        class="letter" @animationend="removeLetter(index)">
        {{ letter.value }}
      </div>
    </transition-group>
    <!-- Slot to allow custom HTML content -->
    <slot></slot>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';


export default {
  setup() {
    let id = 0;
    const letters = ref([]);

    function dropLetter() {
      const letter = String.fromCharCode(Math.random()*(33-126)+126);
      const left = Math.random() * window.innerWidth;
      const duration = Math.random() * 2 + 2;
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
      const opacity = Math.random() * 0.5 + 0.5;
      const fontSize = window.innerWidth <= 700 ? Math.random() * (40 - 10) + 10 : Math.random() * (60 - 20) + 20;

      letters.value.push({ value: letter, left, duration, color, opacity, fontSize, id: id++ });
    }

    function removeLetter(index) {
      letters.value.splice(index, 1);
    }

    function resizeHandler() {
      if(window.innerWidth <= 700){
        letters.value.forEach(letter => {
          letter.fontSize = Math.random() * (40 - 10) + 10;
        })
      }
      else{
        letters.value.forEach(letter => {
          letter.fontSize = Math.random() * (80 - 20) + 20;
        })
      }
    }

    let intervalId;

    onMounted(() => {
      intervalId = setInterval(dropLetter, 50);
      window.addEventListener('resize', resizeHandler);
    });

    onUnmounted(() => {
      clearInterval(intervalId);
      window.removeEventListener('resize', resizeHandler);
    });

    return { letters, removeLetter };
  },
};
</script>

<style scoped>
.drop-letter {
  position: relative;
  height: 60vh;
  overflow: hidden;
  width: 100%;
}

.letter {
  position: absolute;
  top: -20px;
  font-weight: bold;
  animation: fall linear forwards;
  z-index: 0;
}

@keyframes fall {
  0% {
    transform: translate(0, -100%) rotate(0deg);
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    transform: translate(0, 100vh) rotate(360deg);
    opacity: 0;
  }
}
</style>
