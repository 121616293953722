<template>
  <div>
    <!-- <button @click="downloadImage">下载图片</button> -->
    <DropLetter style="margin-bottom: 100px;">
      <v-img class="mainlogo mx-auto" src="../assets/logo_text.png"></v-img>
    </DropLetter>

    <TitleModel>
      <h1>2024届招新专栏</h1>
    </TitleModel>
    <div class="infocardbox mx-auto">
      <v-card class="infocard">
        <v-img src="../assets/recruitment/gzh.jpg"></v-img>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5" style="font-weight: bold;">
              互联科创公众号
            </v-card-title>
            <v-card-text style="max-width: 300px;">扫码关注我们的公众号，第一时间获取最新资讯和通知。包括纳新通知、面试通知等都会在公众号第一时间发布！</v-card-text>
          </div>
        </div>
      </v-card>

      <v-card class="infocard">
        <v-img src="../assets/recruitment/nxq.jpg"></v-img>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5" style="font-weight: bold;">
              招新咨询群
            </v-card-title>
            <v-card-text style="max-width: 300px;">扫码加入互联科创招新咨询群，接收最新消息的同时还有所有部门的学长学姐亲自答疑解惑哦~</v-card-text>
          </div>
        </div>
      </v-card>

    </div>

    <div class="infocardbox mx-auto">
      <!-- <v-card class="infocard" max-width="344">
        <v-card-title class="text-h5" style="font-weight: bold;">面试查询</v-card-title>
        <v-card-subtitle>2023届互联科创协会面试查询</v-card-subtitle>
        <v-card-text>
          查询互联网科技创新协会2023届招新10.14日面试的推荐时间以及注意事项等。
        </v-card-text>
        <v-card-actions>
          <v-btn @click="navigateToDestination('/recruitment/query')" variant="text" color="indigo-accent-4"
            style="font-weight: bold;">
            点击查询
          </v-btn>
        </v-card-actions>
      </v-card> -->

      <v-card class="infocard" max-width="344">
        <v-card-title class="text-h5" style="font-weight: bold;">立即报名</v-card-title>
        <v-card-subtitle>2024届互联科创协会招新报名表</v-card-subtitle>
        <v-card-text>
          迫不及待加入我们啦？点击下面的按钮直接填写报名表吧！
        </v-card-text>
        <v-card-actions>
          <v-btn @click="navigateToDestination('/recruitment/application')" variant="text" color="indigo-accent-4"
            style="font-weight: bold;">
            立即报名
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="infocard" max-width="344">
        <v-card-title class="text-h5" style="font-weight: bold;">关于互联科创</v-card-title>
        <v-card-subtitle>2024届互联科创协会介绍</v-card-subtitle>
        <v-card-text>
          协会介绍详细说明了互联科创协会的历史、目标、人员构成和组织架构等内容！
        </v-card-text>
        <v-card-actions>
          <v-btn @click="navigateToDestination('/about')" variant="text" color="indigo-accent-4"
            style="font-weight: bold;">
            点击查看
          </v-btn>
        </v-card-actions>
      </v-card>


    </div>


    <v-divider></v-divider>

    <TitleModel>
      <h1>协会新闻</h1>
    </TitleModel>
    <div class="infocardbox mx-auto">

      <v-card class="infocard" max-width="450" v-for="new_item in news">
        <v-img class="align-end text-black" height="200" :src="new_item.img" cover>
          <v-card-title style="background-color: rgba(255, 255, 255, 0.8); font-weight: bold;">{{ new_item.title
          }}</v-card-title>
        </v-img>
        <v-card-subtitle class="pt-4">
          {{ new_item.date }}
        </v-card-subtitle>
        <v-card-text>
          <div>{{ new_item.info }}</div>
        </v-card-text>
        <v-card-actions>
          <a :href="new_item.link" style="color: black;">
            <v-btn>
              查看详情
            </v-btn>
          </a>

        </v-card-actions>
      </v-card>


    </div>

    <v-divider></v-divider>

  </div>
</template>

<script>
import { ref, defineComponent } from 'vue';

// Components
import DropLetter from '@/components/DropLetter.vue';
import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
  name: 'Home',
  components: {
    DropLetter,
    TitleModel
  },
  data: () => ({
    news: [
      {
        img: require('../assets/newsimg/cumcm.jpg'),
        title: '互联科创组织参与2023届数学建模国赛',
        date: '2023/9/7',
        info: '在竞赛部的统一协调和组织下，互联科创协会共计组织7支队伍前往校本部参加2023届高教社杯全国大学生数学建模竞赛。',
        link: 'http://www.mcm.edu.cn/'
      },
      {
        img: require('../assets/newsimg/cumcmlt.png'),
        title: '第七届航院杯数学(建模)竞赛 & 广航数学(建模)竞赛论坛来啦！',
        date: '2023/5/14',
        info: '本次论坛由人文社科学院、互联网科技创新协会和数学协会联合举办，两个协会在往届建模竞赛中均取得了非常优异的成绩。',
        link: 'https://mp.weixin.qq.com/s?__biz=Mzg5NDU0ODc0OA==&mid=2247486130&idx=1&sn=df82111671a0604a9f13056eef885d4b&chksm=c01ca4d1f76b2dc71dbadc4b309576dd9269a871b664786d0407cb4738dcb955a4726f714296&scene=126&sessionid=1695358941#rd'
      },
      {
        img: require('../assets/newsimg/lqb.png'),
        title: '2023蓝桥杯大赛圆满结束',
        date: '2023/4/13',
        info: '由广州民航学院承办，互联网科技创新协会协办的2023蓝桥杯全国软件和信息技术专业人才大赛在我校花都赤坭校区圆满结束。',
        link: 'https://mp.weixin.qq.com/s?__biz=Mzg5NDU0ODc0OA==&mid=2247486024&idx=1&sn=2b6a73b5f1054067eeb8568cdb7d408a&chksm=c01ca42bf76b2d3d557aef31a34fccdc8f072c6cf70cbe9fadcc9724f30d3d2b5c84d639dd3a&scene=126&sessionid=1695358941#rd'
      },
    ]
  }),
  methods: {
    navigateToDestination(url) {
      this.$router.push(url); // 导航到目标页面
    },
    
  }
});
</script>

<style scoped>
.mainlogo {
  width: 20vw;
  margin-top: 15vh;
}

.infocardbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  padding: 50px;
  padding-top: 0px;
}

.infocard {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 991px) {
  .mainlogo {
    width: 50vw;
  }
}
</style>