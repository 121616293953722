<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>理事会</h1>
    </TitleModel>

    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <p>本协会设常设决策机构广州民航职业技术学院互联网科技创新协会理事会会议，理事会成员由协会会长、副会长及各部门（组别）负责人组成，在全体大会闭会期间代为行使全体大会大部分职权。</p>
        <v-card class="mx-auto" style="margin-top: 20px;">
            <v-card-title>投诉与建议</v-card-title>
            <v-card-text style="text-align: left;">
                <p><strong>理事会信箱：</strong>council@gcacitia.com</p>
                <p><strong>会长信箱：</strong>president@gcacitia.com</p>
                <p><strong>指导老师邮箱：</strong>hongruifeng@gcacitia.com</p>
            </v-card-text>
        </v-card>
    </div>

    <TitleModel>
        <h2>理事会会长</h2>
    </TitleModel>

    <v-card class="mx-auto" style="max-width: 1000px;">
        <div class="cardflex">
            <v-avatar class="ma-3" size="200" rounded="0">
                <v-img src="../../assets/member/李佑铭.png"></v-img>
            </v-avatar>
            <div>
                <v-card-title class="text-h5">
                    李佑铭
                </v-card-title>
                <v-card-subtitle><strong>理事会会长 </strong><strong>责任部门：</strong>竞赛与项目部、电子部</v-card-subtitle>
                <v-card-subtitle><strong>邮箱：</strong>liyouming@gcacitia.com</v-card-subtitle>
                <v-card-text>22通航管制1班，2021年加入计算机协会，历任2022、2023届互联网科技创新协会副会长，现任互联网科技创新协会会长。华为云HCSD校园大使，华为云HCSD广州民航职业技术学院社群负责人。

                    持有阿里云人工智能助理工程师认证、工业和信息化部数学建模专业技能认证、HarmonyOS应用开发者高级认证。

                    精通Python程序设计、Web应用开发、C#程序设计、Unity游戏开发、Electron跨平台开发、ArkTS鸿蒙开发等开发框架和引擎，曾作为三个大型商业软件项目主程序参与开发。

                    曾荣获蓝桥杯大赛Python程序设计全国总决赛二等奖、全国大学生数学建模竞赛全国二等奖等5项国家级荣誉，以及20余项省部级荣誉，并发表学术论文1篇。</v-card-text>
            </div>
        </div>
    </v-card>

    <div style="padding-left: 15px; padding-right: 15px; margin-top: 20px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/林志达.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        林志达
                    </v-card-title>
                    <v-card-subtitle><strong>理事会副会长 </strong><strong>责任部门：</strong>编程部、技术部</v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>linzhida@gcacitia.com</v-card-subtitle>
                    <v-card-text>23应用电子技术2班，原互联网科技创新协会竞赛与项目部成员，现任2024届互联网科技创新协会副会长兼编程部部长。

                        擅长软件和项目开发，精通多种编程语言和基本数据结构，包括C语言、Python、Web开发、Node.js和Flask框架等。曾开发过数字游戏趋势分析预测系统。

                        积极参与数学建模比赛、国家安全微视频大赛、电子设计创意节等活动，同时参与华为HCSD圆桌论坛、花都高校科技社团联盟交流会。在团队协作和指导方面具备丰富经验。还撰写过《一般学习法：目标学习和团队任务》一书。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/许星璨.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        许星璨
                    </v-card-title>
                    <v-card-subtitle><strong>理事会副会长 </strong><strong>责任部门：</strong>双创部、运营部</v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>xuxingcan@gcacitia.com</v-card-subtitle>
                    <v-card-text>23大数据技术1班，原互联网科技创新协会双创部成员，现任2024届互联网科技创新协会副会长兼双创部部长，同时担任班级组织委员和数学课代表。

                        在学业上，保持脚踏实地的学习态度，取得优异成绩；在工作中，积极担任各项职责，乐于奉献并不断反思，以改进工作方法。

                        荣获2023-2024年度广州民航职业技术学院“优秀共青团干部”称号，参与并获得民航电子信息工程学院第十四届运动会接力赛第三名，以及2024年度国家安全教育微视频大赛三等奖和起航杯“金电子”创意大赛铜奖等多项荣誉。</v-card-text>
                </div>
            </div>
        </v-card>
    </div>

    <TitleModel>
        <h2>理事会成员</h2>
    </TitleModel>
    <div style="display: flex; justify-content: center; width: 100%;">
        <div style="text-align: left; padding-left: 30px; padding-right: 30px;">
            <h3>编程部团队：李晓霖、吕豪浚、黄予政、海金鹏、杜婷婷、王爱琳</h3>
            <h3>运营部团队：裴莹莹、叶兴</h3>
            <h3>双创部团队：韩雨笑、欧阳瑶</h3>
            <h3>技术部团队：黄嘉晖、冷昊泽、林明阳</h3>
            <h3>电子部团队：李翰伦、钟鹏涛</h3>
        </div>
    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({

    }),
    methods: {

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.cardflex {
    display: flex;
    flex-wrap: nowrap;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>