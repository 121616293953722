<template>
    <v-img class="mainlogo mx-auto" src="../assets/Zifukj.png"></v-img>
    <TitleModel>
        <h1 style="letter-spacing: 5px;">2024年数创杯大学生数学建模竞赛</h1>
    </TitleModel>

    <!-- 根据当前时间判断是否显示倒计时还是正计时 -->
    <div v-if="isBeforeStart" class="countdown-container">
        <h2 class="countdown">
            <span>{{ formattedDays }}</span> 天 
            <span>{{ formattedHours }}</span> 小时 
            <span>{{ formattedMinutes }}</span> 分钟 
            <span>{{ formattedSeconds }}</span> 秒
        </h2>
        <p class="countdown-text">距离竞赛开始还有</p>
    </div>
    
    <div v-else class="countdown-container">
        <h2 class="countdown">
            <span>{{ formattedRemainingDays }}</span> 天 
            <span>{{ formattedRemainingHours }}</span> 小时 
            <span>{{ formattedRemainingMinutes }}</span> 分钟 
            <span>{{ formattedRemainingSeconds }}</span> 秒
        </h2>
        <p class="countdown-text">距离比赛结束还有</p>
    </div>

    <v-card v-if="!isBeforeStart" class="mx-auto application-form">
        <v-card-title>赛题解压密码</v-card-title>
        <v-card-subtitle>请通过该密码解压赛题</v-card-subtitle>
        <v-card-text>
            <h2>SCB@ITIAgcac2024#</h2>
        </v-card-text>
    </v-card>

    <v-card v-if="isDownloadStart" class="mx-auto application-form" style="margin-top: 20px;">
        <v-card-title>赛题下载</v-card-title>
        <v-card-subtitle>点击下方下载赛题，赛题解压密码将于比赛开始时准时公布</v-card-subtitle>
        <v-card-actions>
            <v-btn @click="linkTo('https://api.gcacitia.com/i/file/download/downloadsite/asset/2024.zip')">2024年数创杯大学生数学建模竞赛赛题.zip</v-btn>
        </v-card-actions>
    </v-card>

    <v-card class="mx-auto application-form" style="margin-top: 20px;">
        <v-card-title>资源下载</v-card-title>
        <v-card-subtitle>点击下方下载</v-card-subtitle>
        <v-card-actions>
            <v-btn @click="linkTo('https://api.gcacitia.com/i/file/download/downloadsite/asset/PaperModel.docx')">论文模板.docx</v-btn>
        </v-card-actions>
    </v-card>

    <v-card class="mx-auto application-form" style="margin-top: 20px;">
        <v-card-title>大赛组织</v-card-title>
        <v-card-text>
            <p><strong>主办单位：</strong>东莞市字符科技有限公司</p>
            <p><strong>承办单位：</strong>广州民航职业技术学院互联网科技创新协会</p>
            <p><strong>协办单位：</strong>广州民航职业技术学院数学协会</p>
            <p><strong>组织委员会：</strong>李佑铭、陈湘珏、林志达、黄予政、李朝鑫、裴莹莹</p>
            <p><strong>命题组：</strong>李佑铭、林志达、黄予政</p>
            <p><strong>专家组：</strong>黄宇飞 教授、高卓 副教授</p>
        </v-card-text>
    </v-card>

    <v-card class="mx-auto application-form" style="margin-top: 20px;">
        <v-card-title>联系方式</v-card-title>
        <v-card-text>
            <p><strong>组织委员会：</strong>council@gcacitia.com</p>
            <p><strong>主办单位：</strong>service@charactech.cn</p> 
        </v-card-text>
    </v-card>
</template>

<script>
import { ref, defineComponent } from 'vue';
import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({
        isBeforeStart: true,  // 判断是否在倒计时阶段
        isDownloadStart: false,
        downloadTargetDate: new Date('2024-11-21T03:00:00Z'),  // 允许下载赛题（UTC时间）
        firstTargetDate: new Date('2024-11-21T04:00:00Z'),  // 比赛开始时间（UTC时间）
        secondTargetDate: new Date('2024-11-26T12:00:00Z'),  // 比赛结束时间（UTC时间）
        currentTime: new Date(),  // 当前时间
        interval: null,  // 定时器
    }),
    computed: {
        // 格式化时间：确保个位数前补零
        formattedDays() {
            return String(this.days).padStart(1, '0');
        },
        formattedHours() {
            return String(this.hours).padStart(2, '0');
        },
        formattedMinutes() {
            return String(this.minutes).padStart(2, '0');
        },
        formattedSeconds() {
            return String(this.seconds).padStart(2, '0');
        },

        formattedRemainingDays() {
            return String(this.remainingDays).padStart(1, '0');
        },
        formattedRemainingHours() {
            return String(this.remainingHours).padStart(2, '0');
        },
        formattedRemainingMinutes() {
            return String(this.remainingMinutes).padStart(2, '0');
        },
        formattedRemainingSeconds() {
            return String(this.remainingSeconds).padStart(2, '0');
        },

        // 倒计时的天数、小时、分钟、秒（如果比赛未开始）
        days() {
            const diff = this.firstTargetDate - this.currentTime;
            return Math.max(0, Math.floor(diff / (1000 * 60 * 60 * 24)));
        },
        hours() {
            const diff = this.firstTargetDate - this.currentTime;
            return Math.max(0, Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        },
        minutes() {
            const diff = this.firstTargetDate - this.currentTime;
            return Math.max(0, Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
        },
        seconds() {
            const diff = this.firstTargetDate - this.currentTime;
            return Math.max(0, Math.floor((diff % (1000 * 60)) / 1000));
        },

        // 正计时：距离比赛结束的天数、小时、分钟、秒（如果比赛已开始）
        remainingDays() {
            const diff = this.secondTargetDate - this.currentTime;
            return Math.max(0, Math.floor(diff / (1000 * 60 * 60 * 24)));
        },
        remainingHours() {
            const diff = this.secondTargetDate - this.currentTime;
            return Math.max(0, Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        },
        remainingMinutes() {
            const diff = this.secondTargetDate - this.currentTime;
            return Math.max(0, Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
        },
        remainingSeconds() {
            const diff = this.secondTargetDate - this.currentTime;
            return Math.max(0, Math.floor((diff % (1000 * 60)) / 1000));
        },
    },
    methods: {
        linkTo(url){
            window.open(url, '_blank')
        },
        updateTime() {
            this.currentTime = new Date();

            // 判断是否已经过了2024年11月21日12:00，切换到正计时阶段
            if (this.currentTime >= this.downloadTargetDate) {
                this.isDownloadStart = true;  // 进入正计时阶段
            }

            // 判断是否已经过了2024年11月21日12:00，切换到正计时阶段
            if (this.currentTime >= this.firstTargetDate && this.currentTime < this.secondTargetDate) {
                this.isBeforeStart = false;  // 进入正计时阶段
            }

            // 如果已经超过2024年11月26日20:00，则停止定时器
            if (this.currentTime >= this.secondTargetDate) {
                clearInterval(this.interval);  // 停止定时器
            }
        },
    },
    mounted() {
        this.updateTime();  // 初始更新一次
        this.interval = setInterval(this.updateTime, 1000);  // 每秒更新一次
    },
    beforeDestroy() {
        clearInterval(this.interval);  // 清理定时器
    }
});
</script>

<style scoped>
.mainlogo {
    width: 5vw;
    margin-top: 5vh;
}

.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    text-align: center;
}

.countdown {
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: #333;
}

.countdown-text {
    font-size: 1.2rem;
    color: #777;
    margin-top: 10px;
}

/* 动画效果 */
.countdown span {
    display: inline-block;
    transition: transform 0.3s ease-in-out; /* 平滑动画效果 */
}

.countdown span:nth-child(odd) {
    color: #2b8a3e;
}

.countdown span:nth-child(even) {
    color: #e77c55;
}

.countdown span:hover {
    transform: scale(1.2);  /* 添加悬停时的缩放动画 */
}

/* 自适应样式 */
@media (max-width: 991px) {
    .mainlogo {
        width: 25vw;
    }

    .countdown {
        font-size: 1.5rem;
    }

    .countdown-text {
        font-size: 1rem;
    }
}
</style>
