<template>
    <div class="mx-auto" style="max-width: 1000px; padding: 20px;">
        <v-img class="mainlogo mx-auto" src="../assets/logo_text.png"></v-img>
        <TitleModel>
            <h1>协会简介</h1>
        </TitleModel>
        <p>互联网科技创新协会是由民航电子信息工程学院负责指导、校团委批准建立的学生社团。互联科创协会是目前广州民航职业技术学院中<b>规模最大、实力最强、涉及最广</b>的科技类社团，成员超百人。</p>

        <p>协会下设<b>编程部、技术部、电子部、双创部、运营部、竞赛与项目部</b>六个部门，覆盖软件、硬件、学科竞赛、创新创业、自媒体等多个领域，均取得优秀成绩，在算法竞赛、数学建模等专业学科竞赛领域长期保持全校第一水平。</p>

        <v-timeline align="start" side="end">
            <v-timeline-item>
                <template v-slot:opposite>
                    20年10月
                </template>
                <div>
                    <div class="text-h6">计算机协会创立</div>
                    <p>
                        在以19信管1班赖成傑、刘原林等学生提议、航空港管理学院计算机系主任洪锐锋老师的支持下，广州民航职业技术学院计算机协会正式成立，是继电脑爱好者协会注销以来广州民航职业技术学院第一个科技类、计算机类学生社团。
                        计算机协会创立时设编程部、技术部、行政部、运营部四个部门，分别负责软件、硬件、创新创业、运营策划等方向。
                    </p>
                </div>
            </v-timeline-item>

            <v-timeline-item>
                <template v-slot:opposite>
                    21年9月
                </template>
                <div>
                    <div class="text-h6">计算机协会正式注册</div>
                    <p>
                        2021年9月，在以时任理事会会长20计应1班翁裕健为代表的理事会团队努力下，计算机协会正式通过校团委验收，正式注册为由航空港管理学院作为指导组织，航空港管理学院计算机系主任洪锐锋副教授为指导教师的学生社团组织。
                    </p>
                </div>
            </v-timeline-item>

            <v-timeline-item>
                <template v-slot:opposite>
                    22年6月
                </template>
                <div>
                    <div class="text-h6">计算机协会双创部设立</div>
                    <p>
                        2022年6月，计算机协会换届大会选举表决通过以理事会会长21计应1班董嘉诚、副会长21大数据1班朱嘉俊为代表的第三届理事会团队。由董嘉诚会长提出提案、第三届理事会表决通过决议，行政部正式裁撤，专注于创新创业的
                        双创部正式成立，继承行政部职能，专注于创新创业大赛。
                    </p>
                </div>
            </v-timeline-item>

            <v-timeline-item>
                <template v-slot:opposite>
                    22年10月
                </template>
                <div>
                    <div class="text-h6">计算机协会正式更名</div>
                    <p>
                        2022年10月，计算机协会2022届第一次全体大会上表决通过了《关于计算机协会更名为互联网科技创新协会的提案》、《互联网科技创新协会章程》，经由指导老师报校团委批准，
                        计算机协会正式更名为互联网科技创新协会。
                    </p>
                </div>
            </v-timeline-item>
        </v-timeline>
    </div>
</template>

<script>
import TitleModel from '@/components/TitleModel.vue';

export default {

    components: {
        TitleModel,
    },

}
</script>

<style>
p {
    margin-bottom: 10px;
}
</style>