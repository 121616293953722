<template>
    <v-img class="mainlogo mx-auto" src="../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>下载中心</h1>
    </TitleModel>

    <TitleModel>
        <h2>设计类素材</h2>
    </TitleModel>

    <v-container class="cardsbox">
        <v-row align="center" justify="center">
            <v-col cols="auto" v-for="(card, i) in cards" :key="i">
                <v-card class="mx-auto" max-width="344" min-width="200">
                    <v-card-item>
                        <div>
                            <div class="text-h6 mb-1" style="font-weight: bold;">
                                {{ card.name }}
                            </div>
                            <div class="text-caption">{{ card.info }}</div>
                        </div>
                    </v-card-item>
                    <v-card-actions>
                        <v-btn @click="downloadImage(card.name, card.link)" style="font-weight: bold;">
                            立即下载
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import TitleModelDown from '@/components/TitleModelDownText.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel,
        TitleModelDown
    },
    data: () => ({

        cards: [

            {
                name: '互联科创Logo含字',
                info: '分辨率：1890 × 1417',
                link: '@/assets/logo_text.png'
            },
            {
                name: '互联科创Logo纯图',
                info: '分辨率：604 × 432',
                link: '@/assets/logo.png'
            },
            {
                name: '互联科创会徽',
                info: '分辨率：912 × 909',
                link: '@/assets/互创会徽透明.png'
            },
            {
                name: '计算机协会Logo',
                info: '分辨率：704 × 815',
                link: '@/assets/计协Logo.png'
            },
            {
                name: '广航校徽无字',
                info: '分辨率：1112 × 1105',
                link: '@/assets/广航校徽无字.png'
            },
            {
                name: '广航校徽',
                info: '分辨率：1403 × 1403',
                link: '@/assets/广航校徽.png'
            },
            {
                name: '广航校名',
                info: '分辨率：1403 × 329',
                link: '@/assets/GCACLogoText.png'
            },
            {
                name: '公众号二维码',
                info: '分辨率：860 × 860',
                link: '@/assets/公众号.png'
            }
            // {
            //     name: '竞赛和项目部',
            //     info: '竞赛和项目部负责协会会员的互联网及计算机知识、编程语言、项目开发、算法设计的拔尖性培养。',
            //     link: ''
            // }
        ]

    }),
    methods: {
        downloadImage(name, url) {
            const imagePath = require(`${url}`); // 导入图片
            const a = document.createElement('a');
            a.href = imagePath;
            a.download = name + '.png'; // 设置下载的文件名
            document.body.appendChild(a);
            a.click(); // 模拟点击链接以触发下载
            document.body.removeChild(a); // 删除临时链接元素
        },

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
}

.infocard {
    max-width: 400px;
}

.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>