<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <div style="text-align: center;">
        <h1 class="mx-auto">2023届招新测试</h1>
    </div>

    <v-form class="mx-auto application-form">
        <!-- 表单输入字段 -->
        <TitleModel>
            <h2>报名信息</h2>
        </TitleModel>
        <v-text-field label="手机号" v-model="phone" :rules="[required]" placeholder="与报名时一致的手机号"></v-text-field>
        <v-text-field label="微信号" v-model="wxid" :rules="[required]" placeholder="用于录取后联络"></v-text-field>

        <TitleModel>
            <h2>招新测试</h2>
        </TitleModel>
        <div style="margin-bottom: 20px;"><strong>填写须知：</strong>可以自行选择任意个部门的测试题完成，不想参与的部门题目可以直接留空，完成想要参与面试的部门测试题即可。</div>

        <v-expansion-panels style="margin-bottom: 10px;">
            <v-expansion-panel title="编程部测试题">
                <v-expansion-panel-text>
                    <div style="margin-bottom: 10px;">以下问题任选其一回答（可多选），请在回答前说明你所选择的问题编号，不需要太复杂，言简意赅即可。</div>
                    <div>1. 如何计算一个年份是不是闰年？请用文字或代码描述思路。</div>
                    <div>2. 假设你站在楼梯底部，每次可以迈一级或两级楼梯。这个楼梯有n级，你要爬到楼梯顶部，有多少种不同的方式可以做到？请用文字或代码描述思路。</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-textarea v-model="bcb" label="编程部测试题回答" auto-grow></v-textarea>

        <v-expansion-panels style="margin-bottom: 10px;">
            <v-expansion-panel title="运营部测试题">
                <v-expansion-panel-text>
                    <div style="margin-bottom: 10px;">以下问题任选其一回答（可多选），请在回答前说明你所选择的问题编号，不需要太复杂，言简意赅即可。</div>
                    <div>1. 你要如何完成一个活动的策划？请选择一个活动类型（讲座、纳新、团建等）并写出你这个活动策划的重点或者列出大纲。</div>
                    <div>2. 你认为一个针对于校园短视频自媒体的创作重点应该是什么？应该选择哪些题材作为账号的核心？</div>
                    <div>3. 你认为一个科技类、数码类图文自媒体的创作重点应该是什么？应该选择哪些题材作为账号的核心？</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-textarea v-model="yyb" label="运营部测试题回答" auto-grow></v-textarea>

        <v-expansion-panels style="margin-bottom: 10px;">
            <v-expansion-panel title="技术部测试题">
                <v-expansion-panel-text>
                    <div style="margin-bottom: 10px;">回答以下问题，不会的可以留空，请在回答前说明问题编号，不需要太复杂，言简意赅即可。</div>
                    <div>1. 什么是RAM和ROM？它们分别用于什么目的？</div>
                    <div>2. 如何安装操作系统，例如Windows，它们的安装步骤是什么？</div>
                    <div>3. 如何正确安装CPU到主板上？请简单描述相关步骤。</div>
                    <div>4. 单个内存条和双个内存条怎么装？请简单描述相关步骤。</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-textarea v-model="jsb" label="技术部测试题回答" auto-grow></v-textarea>

        <v-expansion-panels style="margin-bottom: 10px;">
            <v-expansion-panel title="双创部测试题">
                <v-expansion-panel-text>
                    <div style="margin-bottom: 10px;">以下问题任选其一回答（可多选），请在回答前说明你所选择的问题编号，不需要太复杂，言简意赅即可。</div>
                    <div>1. 列举出至少两个你认为很成功的创业公司的名称，并解释他们的成功之处。</div>
                    <div>2. 创新的例子：请提供一个你认为很有趣的创新例子，并解释它是如何改变某个领域的。</div>
                    <div>3. 假设你有一个创业点子，你会如何制定一个初步的创业计划？请提供至少三个关键步骤，以及解释每个步骤的重要性。</div>
                    <div>4. 选择一个特定的行业（如科技、健康、绿色能源等），并描述该行业的当前趋势和未来可能的机会。</div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-textarea v-model="scb" label="双创部测试题回答" auto-grow></v-textarea>


        <!-- 其他表单元素 -->
        <!-- ... -->
        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                :loading="sendloading">提交</v-btn>
        </div>

    </v-form>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {

        TitleModel
    },
    data: () => ({
        phone: '',
        wxid: '',

        bcb: '',
        yyb: '',
        jsb: '',
        scb: '',

        sendloading: false
    }),
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        },
        navigateToDestination(url) {
            window.open(url, '_blank');
        },
        send() {
            this.sendloading = true
            const dataToSend = {
                phone: this.phone,
                wxid: this.wxid,

                bcb: this.bcb,
                yyb: this.yyb,
                jsb: this.jsb,
                scb: this.scb,
            };

            this.sendloading = true
            axios.post('https://api.gcacitia.com/home/recruitment/sendQuery', { phone: this.phone })
                .then(response => {
                    this.sendloading = false

                    if (response.data.error == 'tuple index out of range') {
                        alert('手机号不存在或未报名')
                        return
                    }

                    axios
                        .post('https://api.gcacitia.com/home/recruitment/sendExam', dataToSend)
                        .then(response => {
                            alert('提交成功，准备面试吧~')
                            this.$router.push('/');
                        })
                        .catch(error => {
                            // 处理错误
                            alert('发生错误，请联系微信：leoleeym')

                        });


                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });


        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.application-form {
    width: 550px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .application-form {
        width: 90vw;
    }
}
</style>