<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>电子部</h1>
    </TitleModel>

    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <p>电子部是一个充满活力和创造力的团队，我们的目标是通过单片机开发和技术探索，为协会带来新的活力和创新。我们的工作将围绕以下几个方面展开：</p>
        <p>1.单片机开发：我们将深入研究各种单片机，如Arduino、STM32等，学习其原理和应用，为我们的硬件项目提供强大的支持。</p>
        <p>2.编程技能：我们将学习和掌握各种编程语言，如C/C++、Python等，以便更好地进行硬件开发和控制。</p>
        <p>3.创意设计：我们将结合所学知识，设计和制作一些有趣、有教育意义的硬件项目，如智能家居、机器人、游戏控制器等。</p>
        <p>4.技术分享：我们将定期组织技术交流会，分享我们的学习成果和项目经验，提高团队的整体技术水平。</p>
        <p>5.合作与竞赛：我们将积极参与各类硬件相关的竞赛和活动，与其他团队交流合作，提升自己的能力。</p>
        <p>加入电子部，你将有机会：</p>
        <p>学习到丰富的单片机开发和编程知识，提高自己的技能水平。</p>
        <p>参与到有趣的硬件项目中，锻炼自己的实践能力和团队协作能力。</p>
        <p>结识志同道合的朋友，共同探讨技术问题，拓宽自己的视野。</p>
        <p>展示自己的才华，为计算机协会的发展做出贡献。</p>
        <p>如果你对单片机、编程感兴趣，欢迎加入我们的电子部，一起探索硬件世界，创造更多有趣的作品！</p>
        <!-- <v-card class="mx-auto" style="margin-top: 20px;">
            <v-card-title>部长寄语</v-card-title>
            <v-card-subtitle>运营部负责人 凌佳欣</v-card-subtitle>
            <v-card-text style="text-align: left;">明日永远新鲜如初，纤尘不染。</v-card-text>
            <v-card-subtitle>运营部负责人 宋雨桐</v-card-subtitle>
            <v-card-text style="text-align: left;">你走过的路才是你的世界。</v-card-text>
        </v-card> -->
    </div>

    <TitleModel>
        <h2>电子部部长团队</h2>
    </TitleModel>

    <div style="padding-left: 15px; padding-right: 15px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/李翰伦.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        李翰伦
                    </v-card-title>
                    <v-card-subtitle><strong>电子部部长</strong></v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>lihanlun@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2023届加入互联网科技创新协会编程部，2024届就任首届互联网科技创新协会电子部负责人，有丰富的单片机开发经验。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/钟鹏涛.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        钟鹏涛
                    </v-card-title>
                    <v-card-subtitle><strong>电子部副部长</strong></v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>zhongpengtao@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2023届加入互联网科技创新协会编程部，2024届就任首届互联网科技创新协会电子部负责人，有丰富的单片机开发经验。</v-card-text>
                </div>
            </div>
        </v-card>

    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({

    }),
    methods: {

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.cardflex {
    display: flex;
    flex-wrap: nowrap;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>