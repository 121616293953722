<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>理事会</h1>
    </TitleModel>

    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <p>本协会设常设决策机构广州民航职业技术学院互联网科技创新协会理事会会议，理事会成员由协会会长、副会长及各部门（组别）负责人组成，在全体大会闭会期间代为行使全体大会大部分职权。</p>
        <v-card class="mx-auto" style="margin-top: 20px;">
            <v-card-title>投诉与建议</v-card-title>
            <v-card-text style="text-align: left;">
                <p><strong>理事会信箱：</strong>council@gcacitia.com</p>
                <p><strong>会长信箱：</strong>president@gcacitia.com</p>
                <p><strong>指导老师邮箱：</strong>hongruifeng@gcacitia.com</p>
            </v-card-text>
        </v-card>
    </div>

    <TitleModel>
        <h2>理事会会长</h2>
    </TitleModel>

    <div style="padding-left: 15px; padding-right: 15px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/曾江楠.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        曾江楠
                    </v-card-title>
                    <v-card-subtitle><strong>责任部门：</strong>双创部、技术部</v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>zengjiangnan@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2022届加入计算机协会技术部及运营部，2023届就任互联网科技创新协会理事会会长，分管责任部门为双创部和技术部。主攻计算机硬件领域，Intel i7 6700HQ子项上，曾获得全球第13名，合肥市青少年科技比赛区级一等奖，多家省级出版社投稿者，互联网自媒体视频制作者。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/李佑铭.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        李佑铭
                    </v-card-title>
                    <v-card-subtitle><strong>责任部门：</strong>竞赛与项目部</v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>liyouming@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2021届加入计算机协会编程部，曾任22届互联网科技创新协会理事会副会长，现任23届理事会副会长，分管责任部门为竞赛与项目部。阿里云ACA人工智能助理工程师，华为云广州民航学院社群负责人。在校期间获省部级及以上奖项7项，期刊发表科技类学术论文1篇，获软件著作权1项。主攻计算机软件领域，专注于Web前端开发、Python程序与算法设计、Flask后端开发、人工智能、游戏开发领域。知乎平台数码垂类自媒体图文创作者，曾与华为、荣耀、VIVO等知名消费数码品牌展开深入合作。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/谢远宝.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        谢远宝
                    </v-card-title>
                    <v-card-subtitle><strong>责任部门：</strong>编程部、运营部</v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>xieyuanbao@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2022届加入计算机协会运营部，2023年加入互联网科技创新协会双创部，2023届就任互联网科技创新协会理事会副会长，分管责任部门为编程部和运营部。熟悉互联网运营和推广，熟悉多种编程语言和开发工具。主攻网络运营领域。</v-card-text>
                </div>
            </div>
        </v-card>
    </div>

    <TitleModel>
        <h2>理事会成员</h2>
    </TitleModel>
    <div style="text-align: center; padding-left: 30px; padding-right: 30px;">
        <h3>黄卓琪、凌佳欣、康国熙、宋雨桐、王义、李俊学、朱海倩、郭子毅</h3>
        <h3>张子彬、黎幸灵、罗家堡、纪道坤、吴天航、黄紫馨、张丹云、邱培钦</h3>
    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({

    }),
    methods: {

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.cardflex {
    display: flex;
    flex-wrap: nowrap;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>