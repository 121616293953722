<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <div style="text-align: center;">
        <h1 class="mx-auto">2024届招新测试</h1>
    </div>

    <v-form class="mx-auto application-form">
        <!-- 表单输入字段 -->
        <div v-if="!check">
            <TitleModel>
                <h2>报名信息</h2>
            </TitleModel>
            <v-text-field label="手机号" v-model="phone" :rules="[required]" placeholder="与报名时一致的手机号"></v-text-field>
            <v-text-field label="微信号" v-model="wxid" :rules="[required]" placeholder="用于录取后联络"></v-text-field>
            <div style="text-align: center;">
                <v-btn @click="queryData()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                    :loading="sendloading">验证报名</v-btn>
            </div>
        </div>

        <div style="" v-if="check && !showtj">

            <TitleModel>
                <h2>招新测试</h2>
            </TitleModel>
            <p><strong>姓名：</strong>{{ data.name }}</p>
            <p><strong>报名意向：</strong>{{ data.intention }}</p>
            <div style="margin-bottom: 20px;"><strong>填写须知：</strong>可以自行选择任意个部门的测试题完成，不想参与的部门题目可以直接留空，完成想要参与面试的部门测试题即可。
            </div>

            <v-expansion-panels style="margin-bottom: 10px;">
                <v-expansion-panel title="编程部测试题">
                    <v-expansion-panel-text>
                        <div style="margin-bottom: 10px;">以下问题任选其一回答（可多选），请在回答前说明你所选择的问题编号，不需要太复杂，言简意赅即可。</div>
                        <div>1. 如何判断一个数是不是质数？请用文字或代码描述思路。</div>
                        <div>2. 在 HTML 中，{{ "<p>" }}标签是用来做什么的？</div>
                        <div>3. 假设你站在楼梯底部，每次可以迈一级、两级或三级楼梯。这个楼梯有n级，你要爬到楼梯顶部，有多少种不同的方式可以做到？请用文字或代码描述思路。</div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-textarea v-model="bcb" label="编程部测试题回答" auto-grow></v-textarea>
            <v-btn @click="showCodeEdit = !showCodeEdit" style="margin-bottom: 20px;">代码编辑器</v-btn>
            <p v-if="showCodeEdit">你可以在以下代码编辑器更方便的编辑代码，但请注意，请将编写完成的代码复制到回答框，代码编辑器内的代码无法提交。</p>
            <iframe v-if="showCodeEdit" src="https://r.xjq.icu/" frameborder="0" width="100%" height="500px"
                scrolling="no"></iframe>

            <v-expansion-panels style="margin-bottom: 10px;">
                <v-expansion-panel title="运营部测试题">
                    <v-expansion-panel-text>
                        <div style="margin-bottom: 10px;">以下问题任选其一回答（可多选），请在回答前说明你所选择的问题编号，不需要太复杂，言简意赅即可。</div>
                        <div>1. 你要如何完成一个活动的策划？请选择一个活动类型（讲座、纳新、团建等）并写出你这个活动策划的重点或者列出大纲。</div>
                        <div>2. 你认为一个针对于校园自媒体的创作重点应该是什么？应该选择哪些题材作为账号的核心？</div>
                        <div>3. 你对运营部门的未来发展有什么新的想法？（不要局限可以大胆发言）</div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-textarea v-model="yyb" label="运营部测试题回答" auto-grow></v-textarea>

            <v-expansion-panels style="margin-bottom: 10px;">
                <v-expansion-panel title="技术部测试题">
                    <v-expansion-panel-text>
                        <div style="margin-bottom: 10px;">回答以下问题，不会的可以留空，请在回答前说明问题编号，不需要太复杂，言简意赅即可。</div>
                        <div>1. AMD生产的锐龙系列 CPU 与 INTEL 生产的酷睿系列 CPU 在外观上有什么不同？</div>
                        <div>2. 拆装显卡和内存条的时候，正确的操作步骤是什么？</div>
                        <div>3. 硬盘通过储存介质能的不同分为哪两个大类？目前常用的硬盘接口分别有哪几个？</div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-textarea v-model="jsb" label="技术部测试题回答" auto-grow></v-textarea>

            <v-expansion-panels style="margin-bottom: 10px;">
                <v-expansion-panel title="双创部测试题">
                    <v-expansion-panel-text>
                        <div style="margin-bottom: 10px;">以下问题任选其一回答（可多选），请在回答前说明你所选择的问题编号，不需要太复杂，言简意赅即可。</div>
                        <div>1. 列举出至少两个你认为很成功的创业公司的名称，并解释他们的成功之处。</div>
                        <div>2. 创新的例子：请提供一个你认为很有趣的创新例子，并解释它是如何改变某个领域的。</div>
                        <div>3. 假设你有一个创业点子，你会如何制定一个初步的创业计划？请提供至少三个关键步骤，以及解释每个步骤的重要性。</div>
                        <div>4. 选择一个特定的行业（如科技、健康、绿色能源等），并描述该行业的当前趋势和未来可能的机会。</div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-textarea v-model="scb" label="双创部测试题回答" auto-grow></v-textarea>

            <v-expansion-panels style="margin-bottom: 10px;">
                <v-expansion-panel title="电子部测试题">
                    <v-expansion-panel-text>
                        <div style="margin-bottom: 10px;">以下问题任选其一回答（可多选），请在回答前说明你所选择的问题编号，不需要太复杂，言简意赅即可。</div>
                        <div>1. 下列哪些不是单片机的型号？</div>
                        <div>- STC89C52、AT89S52、Mega2560、Uno、Kirin9000、Intel Core i7-13700k</div>
                        <div>2. 设计一个简单的电路，当按下按钮时，电路中的LED灯会亮，松开按钮后灯灭。你会如何连接电池、按钮和LED？（语言描述）</div>
                        <div>3. 如果一个简单的电路中的LED灯不亮，你认为可能的原因有哪些？你会如何排查？</div>
                        <div>4. 在一个数字电路中，0 和 1 代表电路的两种状态。请解释什么是“与门”和“或门”的逻辑功能。</div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-textarea v-model="dzb" label="电子部测试题回答" auto-grow></v-textarea>


            <!-- 其他表单元素 -->
            <!-- ... -->
            <div style="text-align: center;">
                <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                    :loading="sendloading">提交</v-btn>
            </div>
        </div>




    </v-form>



    <div style="padding: 20px; padding-top: 0px;" v-if="showtj">
        <TitleModel>
            <h1>您的笔试已提交</h1>
        </TitleModel>
        <v-card class="infocard mx-auto">

            <TitleModelDown>
                <template v-slot:title>
                    <h1>{{ data.id }}</h1>
                </template>
                <template v-slot:info>
                    <h4>你的编号是</h4>
                </template>
            </TitleModelDown>

            <v-card-text>
                <p style="font-weight: bold; font-size: 1.3rem;">接下来请密切关注已报名通知群（1群、2群均可）的消息，您面试的通知将会在群中推送。</p>
                <p>请将已报名通知群（1群、2群均可）的免打扰关闭。若你未加入已报名通知群，请尽快扫码下面的二维码加入</p>

            </v-card-text>

            <v-img src="../../assets/recruitment/nxq_ybm.jpg"></v-img>
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="text-h5" style="font-weight: bold;">
                        已报名通知群
                    </v-card-title>
                    <v-card-text>扫码加入已报名的通知微信群，群里会及时发布面试等通知，请务必加入！</v-card-text>
                </div>
            </div>
        </v-card>
    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';
import TitleModelDown from '@/components/TitleModelDownText.vue';

export default defineComponent({
    name: 'Home',
    components: {

        TitleModel,
        TitleModelDown
    },
    data: () => ({
        startTime: 0,

        showCodeEdit: false,
        phone: '',
        wxid: '',

        data: null,

        bcb: '',
        yyb: '',
        jsb: '',
        scb: '',
        dzb: '',

        sendloading: false,

        check: false,
        showtj: false
    }),
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        },
        navigateToDestination(url) {
            window.open(url, '_blank');
        },
        queryData() {
            this.sendloading = true
            axios.get(`https://api.gcacitia.com/i/recruitment/application/phone_last4?phone_last4=${this.phone.slice(-4)}`)
                .then(res => {
                    this.data = res.data
                    this.sendloading = false
                    this.check = true
                    this.startTime = Math.floor(Date.now() / 1000)
                    // console.log(this.data)

                })
                .catch((error) => {
                    this.sendloading = false
                    alert('未找到报名信息')
                })
        },
        send() {
            this.sendloading = true
            const dataToSend = {
                bcb: this.bcb,
                yyb: this.yyb,
                jsb: this.jsb,
                scb: this.scb,
                dzb: this.dzb,
                time: Math.floor(Date.now() / 1000) - this.startTime
            };

            this.sendloading = true
            axios.put(`https://api.gcacitia.com/i/recruitment/application/update/${this.data.id}`, {
                wxid: this.wxid,
                questions: JSON.stringify(dataToSend)
            })
                .then(res => {
                    this.sendloading = false
                    this.showtj = true
                })
                .catch((error) => {
                    this.sendloading = false
                    alert('提交失败，请联系工作人员')
                })



        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.application-form {
    width: 550px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .application-form {
        width: 90vw;
    }
}
</style>