<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>2023届招新简章</h1>
    </TitleModel>

    <TitleModel>
        <h2>协会介绍</h2>
    </TitleModel>
    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <p>在这个信息化时代，互联网思维无处不在，互联网渗透到工作、生活、娱乐等各个方面，而创新更是互联网精神的重要体现。</p>
        <p style="margin-top: 10px;">
            广州民航职业技术学院互联网科技创新协会（原计算机协会）正式成立于2021年，定位是学习型科技社团，以提升计算机专业技能、培养创新型人才为目的，为喜爱互联网的同学提供一个交流和学习的平台。</p>
        <p style="margin-top: 10px;">协会的宗旨是“以专业的态度学习，以创新的角度思考”，并以“培养专业态度和创新思维，成就新时代的自己”为发展目标，在丰富课余生活的同时培养创新精神、团队意识、学习及应用能力。
        </p>

    </div>

    <div style="display: flex; align-items: center; max-width: 1000px;" class="mx-auto">
        <div style="display: flex;" class="mx-auto">
            <TitleModelDown>
                <template v-slot:title>
                    <h2>官方合作社团</h2>
                </template>
                <template v-slot:info>
                    <h4>华为云HCSD计划</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>协办单位</h2>
                </template>
                <template v-slot:info>
                    <h4>空港院创新创业大赛</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>全力支持</h2>
                </template>
                <template v-slot:info>
                    <h4>广航计算机系</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>40 +</h2>
                </template>
                <template v-slot:info>
                    <h4>省部级以上荣誉</h4>
                </template>
            </TitleModelDown>
        </div>
    </div>

    <div style="display: flex; align-items: center; max-width: 1000px;" class="mx-auto">
        <div style="display: flex;" class="mx-auto">

            <TitleModelDown>
                <template v-slot:title>
                    <h2>先进团支部</h2>
                </template>
                <template v-slot:info>
                    <h4>2022届校团委</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>主办单位</h2>
                </template>
                <template v-slot:info>
                    <h4>广航数学建模竞赛论坛</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>2500+ 元</h2>
                </template>
                <template v-slot:info>
                    <h4>协会自设奖学金发放</h4>
                </template>
            </TitleModelDown>
        </div>
    </div>







    <TitleModel>
        <h2>编程部介绍</h2>
    </TitleModel>

    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <v-img style="max-width: 600px;" class="mx-auto" src="../../assets/recruitment/编程部.png"></v-img>
        <p style="margin-top: 30px;">编程部将面向于所有对编程感兴趣的同学，你在这里将收获难度的编程入门和项目经验！</p>
        <p style="margin-top: 10px;">
            并且在本届编程部我们创新性的提出了小组项目模式，由协会的学长学姐们担任组长，带2-4名同学以小组的形式一起入门一门编程语言，然后带着大家一起完成一个完整的项目！在这个模式中大家将可以收获到非常难得的计算机项目经验，对于以后的就业和编程学习都有极大的帮助！
        </p>
        <p style="margin-top: 10px;"><strong>部长寄语：</strong>不必在意别人的眼光，不要忘记自己的初心，以实际行动证明自己。</p>
        <v-btn style="margin-top: 10px;" @click="navigateToDestination('/about/program')">点击查看部门负责人</v-btn>
    </div>


    <div style="display: flex; align-items: center;">
        <div style="display: flex;" class="mx-auto">

            <TitleModelDown>
                <template v-slot:title>
                    <h2>高性能CPU计算平台</h2>
                </template>
                <template v-slot:info>
                    <h4>协会自有硬件</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>高性能GPU计算平台</h2>
                </template>
                <template v-slot:info>
                    <h4>协会自有硬件</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>10000+ 元</h2>
                </template>
                <template v-slot:info>
                    <h4>华为云资源额度</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>直接对接指导</h2>
                </template>
                <template v-slot:info>
                    <h4>华为云技术专家</h4>
                </template>
            </TitleModelDown>
        </div>
    </div>

    <div style="display: flex; align-items: center;">
        <div style="display: flex;" class="mx-auto">

            <TitleModelDown>
                <template v-slot:title>
                    <h2>10+ 台</h2>
                </template>
                <template v-slot:info>
                    <h4>独立公网云服务器</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>重要培养基地</h2>
                </template>
                <template v-slot:info>
                    <h4>蓝桥杯算法竞赛</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>2 个</h2>
                </template>
                <template v-slot:info>
                    <h4>已备案一级域名</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>20 +</h2>
                </template>
                <template v-slot:info>
                    <h4>省部级以上荣誉</h4>
                </template>
            </TitleModelDown>

        </div>
    </div>

    <TitleModel>
        <h2>运营部介绍</h2>
    </TitleModel>
    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <v-img style="max-width: 600px;" class="mx-auto" src="../../assets/recruitment/运营部1.png"></v-img>
        <v-img style="max-width: 600px; margin-top: 10px;" class="mx-auto" src="../../assets/recruitment/运营部2.png"></v-img>
        <p style="margin-top: 30px;">运营部是协会最主要的宣传部门，负责协会公众号的运营以及协会其他的外宣、内宣、活动策划与布置等工作。</p>
        <p style="margin-top: 10px;">以及协会新媒体账号、平台的运营和内容生产等。除此以外，运营部也负责成员的设计类软件培训。</p>
        <p style="margin-top: 10px;"><strong>部长寄语：</strong></p>
        <p>明日永远新鲜如初，纤尘不染。</p>
        <p>你走过的路才是你的世界。</p>
        <v-btn style="margin-top: 10px;" @click="navigateToDestination('/about/operation')">点击查看部门负责人</v-btn>
    </div>

    <div style="display: flex; align-items: center;">
        <div style="display: flex;" class="mx-auto">

            <TitleModelDown>
                <template v-slot:title>
                    <h2>40 %</h2>
                </template>
                <template v-slot:info>
                    <h4>公众号覆盖在校生</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>20000 +</h2>
                </template>
                <template v-slot:info>
                    <h4>自媒体全平台总粉丝</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>1400万</h2>
                </template>
                <template v-slot:info>
                    <h4>自媒体全平台总流量</h4>
                </template>
            </TitleModelDown>

        </div>
    </div>

    <TitleModel>
        <h2>技术部介绍</h2>
    </TitleModel>
    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <v-img style="max-width: 600px;" class="mx-auto" src="../../assets/recruitment/技术部.png"></v-img>
        <p style="margin-top: 30px;">互联科创协会有一个集硬件与软件于一体的部门——技术部，它是同学们学习电脑知识的好窗口，解决电脑问题的好帮手！</p>
        <p style="margin-top: 10px;">技术部主要是针对于计算机硬件的学习和了解，并且面向全校师生提供免费的电脑上门维修服务。</p>
        <p style="margin-top: 10px;"><strong>部长寄语：</strong>哪怕你感觉东风无力，百花凋谢，仍能走出阴郁，一路自有风景。</p>
        <v-btn style="margin-top: 10px;" @click="navigateToDestination('/about/technology')">点击查看部门负责人</v-btn>
    </div>

    <div style="display: flex; align-items: center;">
        <div style="display: flex;" class="mx-auto">

            <TitleModelDown>
                <template v-slot:title>
                    <h2>30 %</h2>
                </template>
                <template v-slot:info>
                    <h4>维修服务覆盖在校生</h4>
                </template>
            </TitleModelDown>

            <TitleModelDown>
                <template v-slot:title>
                    <h2>直接合作培养</h2>
                </template>
                <template v-slot:info>
                    <h4>电脑硬件企业</h4>
                </template>
            </TitleModelDown>


        </div>
    </div>

    <TitleModel>
        <h2>双创部介绍</h2>
    </TitleModel>
    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <v-img style="max-width: 600px;" class="mx-auto" src="../../assets/recruitment/双创部.png"></v-img>
        <p style="margin-top: 30px;">
            双创部是一个基于冲击我校创新创业大赛新高度而设立的学习、竞赛型部门。自2022年上半年伊始，现有20名核心骨干成员，不同的专业，同样的优秀。双创部作为我校创新创业大赛的协办方，双创部成员发挥着显著带头作用。</p>
        <p style="margin-top: 10px;">双创部是一个大家庭，不同专业、天南海北的小伙伴们聚在了一起。只要你有信心、有毅力，都可以加入双创部成为我们的一员~</p>
        <p style="margin-top: 10px;"><strong>部长寄语：</strong></p>
        <p>我们要相信我们可以在平凡的生活中获得不一样的精彩。</p>
        <p>在青春时不留遗憾，不停奋斗吧少年。</p>
        <v-btn style="margin-top: 10px;" @click="navigateToDestination('/about/shuangchuang')">点击查看部门负责人</v-btn>
    </div>


    <TitleModel>
        <h2>活动剪影</h2>
    </TitleModel>
    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <v-img style="max-width: 600px;" class="mx-auto" src="../../assets/recruitment/活动1.png"></v-img>
        <v-img style="max-width: 600px; margin-top: 10px;" class="mx-auto" src="../../assets/recruitment/活动2.png"></v-img>
        <v-img style="max-width: 600px; margin-top: 10px;" class="mx-auto" src="../../assets/recruitment/活动3.png"></v-img>
        <v-img style="max-width: 600px; margin-top: 10px;" class="mx-auto" src="../../assets/recruitment/活动4.png"></v-img>
        <v-img style="max-width: 600px; margin-top: 10px;" class="mx-auto" src="../../assets/recruitment/活动5.png"></v-img>
    </div>

    <TitleModel>
        <h2>加入我们</h2>
    </TitleModel>
    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">

        <v-card class="infocard mx-auto" style="max-width: 600px;">
            <v-img src="../../assets/recruitment/报名表.png"></v-img>
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="text-h5" style="font-weight: bold;">
                        招新报名表
                    </v-card-title>
                    <v-card-actions>
                        <v-btn @click="navigateToDestination('/recruitment/application')" variant="text"
                            color="indigo-accent-4" style="font-weight: bold;">
                            立即报名
                        </v-btn>
                    </v-card-actions>
                </div>
            </div>
        </v-card>

        <v-card class="infocard mx-auto" style="max-width: 600px;">
            <v-img src="../../assets/recruitment/gzh.jpg"></v-img>
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="text-h5" style="font-weight: bold;">
                        互联科创公众号
                    </v-card-title>

                </div>
            </div>
        </v-card>
    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import TitleModelDown from '@/components/TitleModelDownText.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel,
        TitleModelDown
    },
    data: () => ({

    }),
    methods: {
        navigateToDestination(url) {
            this.$router.push(url); // 导航到目标页面
        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
}

.infocard {
    max-width: 400px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>