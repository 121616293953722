<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>面试信息查询</h1>
    </TitleModel>
    <v-form class="mx-auto application-form">
        <!-- 表单输入字段 -->
        <v-text-field label="手机号" v-model="phone" :rules="[required]" placeholder="输入报名时填写的手机号以查询面试信息"></v-text-field>
        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                :loading="sendloading">查询</v-btn>
        </div>



    </v-form>

    <v-card class="application-form mx-auto" style="margin-top: 25px;" v-if="showinfo">
        <v-card-title>你的面试信息</v-card-title>
        <v-card-text>
            <p><strong>ID：</strong>{{ id }}</p>
            <p><strong>姓名：</strong>{{ name }}</p>
            <p><strong>面试部门：</strong>{{ bumen }}</p>
            <p><strong>面试地点：</strong>L2-404</p>
            <p><strong>推荐时间：</strong>10月14日 {{ tuijiantime }}</p>
            <p><strong>面试时间：</strong>10月14日 12:30 - 17:30</p>
        </v-card-text>
        <v-card-text>
            <p>非常感谢报名互联网科技创新协会！上面是你本次面试的信息，为了减少大家的排队等待，我们为每一个人选择了一个合适的面试时间，请<strong>提前十分钟</strong>到达哦。</p>
            <p>当然啦，如果我们推荐时间与你的安排有所冲突，可以选择在我们面试时间中的任何时候来，但是可能要排队。</p>
        </v-card-text>
        <div v-if="showbuchong">
            <v-card-title>自我介绍补充</v-card-title>
            <v-card-text>我们非常的抱歉，由于技术原因您报名时所填写的自我介绍数据丢失(或未填写)，现在需要您重新填写一下。</v-card-text>
            <v-card-text>
                <v-textarea v-model="selfinfo" label="简短的自我介绍" auto-grow></v-textarea>
                <v-btn @click="sendupdate()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                    :loading="sendloading">提交</v-btn>
            </v-card-text>
        </div>

    </v-card>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({
        phone: '',
        sendloading: false,

        id: '',
        name: '',
        bumen: '',
        tuijiantime: '',

        showinfo: false,
        showbuchong: false,

        selfinfo: ''

    }),
    methods: {
        send() {
            this.sendloading = true
            axios.post('https://api.gcacitia.com/home/recruitment/sendQuery', { phone: this.phone })
                .then(response => {
                    this.sendloading = false
                    this.showbuchong = false
                    this.showinfo = false

                    if(response.data.error == 'tuple index out of range'){
                        alert('查询失败，手机号不存在，若需要帮助请联系微信：leoleeym')
                        return
                    }

                    this.id = response.data.id
                    this.name = response.data.name
                    this.bumen = response.data.intention

                    this.showinfo = true

                    if (response.data.selfinfo == null) {
                        this.showbuchong = true
                    }

                    let objectDate = new Date(1697257800000 + (600000 * (Math.floor((Number(this.id) - 10) / 6))));
                    this.tuijiantime = objectDate.getHours() + ':' + (objectDate.getMinutes() < 10 ? '00' : objectDate.getMinutes())
                    console.log(objectDate)
                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        },

        sendupdate() {
            this.sendloading = true
            axios.post('https://api.gcacitia.com/home/recruitment/sendUpdate', { id: this.id, selfinfo: this.selfinfo })
                .then(response => {
                    this.sendloading = false
                    this.showbuchong = false
                    this.showinfo = false
                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        }

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
}

.infocard {
    max-width: 400px;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>