<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>技术部</h1>
    </TitleModel>

    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <p>技术部负责协会会员的计算机硬件知识培训、讲解以及为学校、协会的相关活动提供技术支持。除此之外，还需负责协会面向全校师生提供的维修服务的落实执行。</p>
    </div>

    <TitleModel>
        <h2>技术部负责人</h2>
    </TitleModel>

    <div style="padding-left: 15px; padding-right: 15px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/黄嘉晖.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        黄嘉晖
                    </v-card-title>
                    <v-card-subtitle><strong>邮箱：</strong>huangjiahui@gcacitia.com</v-card-subtitle>
                    <v-card-text>原互联网科技创新协会技术部成员，现互联网科技创新协会技术部部长，有七年电脑维修经验，初通电脑维修，擅长各类电脑问题排查维修，较有耐心，能独自完成大部分维修任务。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/冷昊泽.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        冷昊泽
                    </v-card-title>
                    <v-card-subtitle><strong>邮箱：</strong>lenghaoze@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2023届加入互联网科技创新协会技术部，2024届就任互联网科技创新协会技术部副部长。具备扎实的电脑硬件知识，熟悉拆装电脑，提供校内电脑维修为同学解决问题等。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/林明阳.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        林明阳
                    </v-card-title>
                    <v-card-subtitle><strong>邮箱：</strong>linmingyang@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2023届加入互联网科技创新协会技术部，2024届就任互联网科技创新协会技术部副部长。具备扎实的电脑硬件知识，熟悉拆装电脑，提供校内电脑维修为同学解决问题等。</v-card-text>
                </div>
            </div>
        </v-card>

    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({

    }),
    methods: {

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.cardflex {
    display: flex;
    flex-wrap: nowrap;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>