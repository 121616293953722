<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <div style="text-align: center;">
        <h1 class="mx-auto">2024届招新活动</h1>
    </div>

    <v-form class="mx-auto application-form">
        <!-- 表单输入字段 -->
        <div v-if="!check">
            <TitleModel>
                <h2>填写手机号，立即参与活动</h2>
            </TitleModel>
            <v-text-field label="手机号" v-model="phone" :rules="[required]" placeholder="若已报名，填写与报名时一致的手机号"></v-text-field>
            <!-- <v-text-field label="微信号" v-model="wxid" :rules="[required]" placeholder="用于录取后联络"></v-text-field> -->
            <div style="text-align: center;">
                <v-btn @click="queryData()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                    :loading="sendloading">进入</v-btn>
            </div>
        </div>

        <div v-if="check" style="margin-top: 30px;">
            <v-btn @click="getdata" elevation="0" color="success">刷新</v-btn>

            <v-timeline side="end">
                <v-timeline-item :dot-color="data.hwcloud == 0 ? 'pink' : data.hwcloud == 1 ? 'green' : 'yellow'"
                    size="small">
                    <div class="d-flex">
                        <strong style="width: 90px;" class="me-4">{{ data.hwcloud == 0 ? '未完成' : data.hwcloud == 1 ? '已完成' :
                            '已抽奖' }}</strong>
                        <div>
                            <strong>华为云主机的免费领取</strong>
                            <div class="text-caption">
                                前往互联网科技创新协会招新摊位按照工作人员的指示领取即可
                            </div>
                        </div>
                    </div>
                </v-timeline-item>

                <v-timeline-item :dot-color="data.codelab == 0 ? 'pink' : data.codelab == 1 ? 'green' : 'yellow'"
                    size="small">
                    <div class="d-flex">
                        <strong style="width: 90px;" class="me-4">{{ data.codelab == 0 ? '未完成' : data.codelab == 1 ? '已完成' :
                            '已抽奖' }}</strong>
                        <div>
                            <strong>CodeLab 代码实验室</strong>
                            <div class="text-caption">
                                参加 CodeLab 代码实验室活动，完成一次代码编写体验活动即可
                            </div>
                        </div>
                    </div>
                </v-timeline-item>

                <v-timeline-item :dot-color="data.jsb == 0 ? 'pink' : data.jsb == 1 ? 'green' : 'yellow'" size="small">
                    <div class="d-flex">
                        <strong style="width: 90px;" class="me-4">{{ data.jsb == 0 ? '未完成' : data.jsb == 1 ? '已完成' : '已抽奖'
                        }}</strong>
                        <div>
                            <strong>电脑装机体验</strong>
                            <div class="text-caption">
                                参加电脑装机体验活动，完成一次电脑装机体验活动即可
                            </div>
                        </div>
                    </div>
                </v-timeline-item>

                <v-timeline-item :dot-color="data.dzb == 0 ? 'pink' : data.dzb == 1 ? 'green' : 'yellow'" size="small">
                    <div class="d-flex">
                        <strong style="width: 90px;" class="me-4">{{ data.dzb == 0 ? '未完成' : data.dzb == 1 ? '已完成' : '已抽奖'
                        }}</strong>
                        <div>
                            <strong>单片机体验</strong>
                            <div class="text-caption">
                                参加单片机体验活动，完成一次单片机体验活动即可
                            </div>
                        </div>
                    </div>
                </v-timeline-item>

                <!-- <v-timeline-item :dot-color="data.vr == 0 ? 'pink': data.vr == 1 ? 'green': 'yellow'" size="small">
                    <div class="d-flex">
                        <strong style="width: 90px;" class="me-4">{{ data.vr == 0 ? '未完成': data.vr == 1 ? '已完成': '已抽奖' }}</strong>
                        <div>
                            <strong>VR 游戏体验</strong>
                            <div class="text-caption">
                                参加 VR 游戏体验活动，成功通关游戏即可
                            </div>
                        </div>
                    </div>
                </v-timeline-item> -->

            </v-timeline>
        </div>




    </v-form>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {

        TitleModel
    },
    data: () => ({
        startTime: 0,

        showCodeEdit: false,
        phone: '',
        wxid: '',

        data: null,

        bcb: '',
        yyb: '',
        jsb: '',
        scb: '',
        dzb: '',

        sendloading: false,

        check: false,

        timey: false,
        atyId: ''
    }),
    mounted() {
        let time = this.$route.query.time
        let atyId = this.$route.query.atyId
        // 判断获取到的时间戳和当前差距是否大于两秒
        if (time) {
            if (Math.abs(time - Date.now()) > 5000) {
                alert('登记二维码已过期，请重新获取')
            } else {
                this.timey = true
                this.atyId = atyId
            }
        }

    },
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        },
        navigateToDestination(url) {
            window.open(url, '_blank');
        },
        queryData() {
            this.sendloading = true
            axios.get(`https://api.gcacitia.com/i/recruitment/activity/${this.phone}`)
                .then(res => {
                    this.data = res.data.activity
                    this.sendloading = false
                    this.check = true
                    this.startTime = Math.floor(Date.now() / 1000)

                    if (this.timey) {
                        if (this.atyId != '') {
                            if (this.atyId == 'cj') {
                                let cs = 0
                                if (this.data.hwcloud == 1) {
                                    cs += 1
                                    this.data.hwcloud = 2
                                }
                                if (this.data.dzb == 1) {
                                    cs += 1
                                    this.data.dzb = 2
                                }
                                if (this.data.jsb == 1) {
                                    cs += 1
                                    this.data.jsb = 2
                                }
                                if (this.data.codelab == 1) {
                                    cs += 1
                                    this.data.codelab = 2
                                }
                                if (this.data.vr == 1) {
                                    cs += 1
                                    this.data.vr = 2
                                }

                                axios.put(`https://api.gcacitia.com/i/recruitment/activity/update/${res.data.id}`, {
                                    activity: JSON.stringify(this.data)
                                }).then(res => {
                                    this.getdata()
                                })

                                alert('成功兑换抽奖机会 ' + cs.toString() + ' 次')
                            }
                            else {
                                if (this.data[this.atyId] == 2) {
                                    alert('您已经兑换过该活动')
                                } else {
                                    this.data[this.atyId] = 1
                                    axios.put(`https://api.gcacitia.com/i/recruitment/activity/update/${res.data.id}`, {
                                        activity: JSON.stringify(this.data)
                                    }).then(res => {
                                        this.getdata()
                                    })
                                }

                            }

                        }
                    }

                })
                .catch((error) => {
                    this.sendloading = false
                    alert('未找到报名信息')
                })
        },
        getdata() {
            this.sendloading = true
            axios.get(`https://api.gcacitia.com/i/recruitment/activity/${this.phone}`)
                .then(res => {
                    this.data = res.data.activity
                    this.sendloading = false

                })
                .catch((error) => {
                    this.sendloading = false
                    alert('未找到报名信息')
                })
        },
        send() {
            this.sendloading = true
            const dataToSend = {
                bcb: this.bcb,
                yyb: this.yyb,
                jsb: this.jsb,
                scb: this.scb,
                dzb: this.dzb,
                time: Math.floor(Date.now() / 1000) - this.startTime
            };

            this.sendloading = true
            axios.put(`https://api.gcacitia.com/i/recruitment/application/update/${this.data.id}`, {
                wxid: this.wxid,
                questions: JSON.stringify(dataToSend)
            })
                .then(res => {
                    this.sendloading = false
                    alert('提交成功，准备好面试吧！')
                    this.$router.push('/');
                })
                .catch((error) => {
                    this.sendloading = false
                    alert('提交失败，请联系工作人员')
                })



        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.application-form {
    width: 550px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .application-form {
        width: 90vw;
    }
}
</style>