<template>
    <v-img class="mainlogo mx-auto" src="../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>报修申请</h1>
    </TitleModel>
    <v-form class="mx-auto application-form">
        <!-- 表单输入字段 -->
        <TitleModel>
            <h3>基本信息</h3>
        </TitleModel>
        <v-text-field label="姓名" v-model="name" :rules="[rules.required]" placeholder=""></v-text-field>
        <v-text-field label="手机号" v-model="phone" :rules="[rules.required]" placeholder="用于维修人员和你取得联系"></v-text-field>
        <v-text-field label="微信号" v-model="wechatid" :rules="[rules.required]" placeholder="用于维修人员和你取得联系"></v-text-field>
        <v-select label="性别" v-model="sex" placeholder="用于安排合适的维修人员" :items="['男', '女']" :rules="[rules.required]"></v-select>

        <v-select label="宿舍楼栋" v-model="dormitory"
            :items="['N1', 'N2', 'N3', 'N4', 'N5', 'N6', 'N7', 'N8', 'N9', 'N10', 'N11', 'N12', 'N13', 'N14', 'N15', 'N16', 'N17', 'N18', '教职人员']"
            :rules="[rules.required]"></v-select>
        <v-select label="所在院系" v-model="department"
            :items="['航空港管理学院', '民航电子信息工程学院', '民航经营管理学院', '飞机维修工程学院', '无人机学院', '空中乘务学院', '教职人员']" :rules="[rules.required]"></v-select>

        <TitleModel>
            <h3>故障信息</h3>
        </TitleModel>
        <v-select label="设备类型" v-model="type" :items="['笔记本电脑', '台式电脑', '其他设备']" :rules="[rules.required]"></v-select>
        <v-select label="设备品牌" v-model="brand" :items="['联想', '华硕', '华为', '戴尔', '惠普', '苹果', '自行组装', '其他']"
            :rules="[rules.required]"></v-select>
        <v-select label="问题类型" v-model="questionType" :items="['电脑清灰', '系统重装', '软件安装', '系统清理', '设备故障', '其他']"
            :rules="[rules.required]"></v-select>

        <v-textarea v-model="info" label="详细描述你的问题" auto-grow :rules="[rules.required]"></v-textarea>

        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;"
                :loading="sendloading">提交</v-btn>
        </div>
    </v-form>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

function checkEmptyValues(data) {
    for (let key in data) {
        if (data.hasOwnProperty(key) && (data[key] === null || data[key] === undefined || data[key] === '')) {
            return true;  // 如果存在空值，则返回 true
        }
    }
    return false;  // 如果没有空值，则返回 false
}


export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({
        name: '',
        phone: '',
        wechatid: '',
        sex: [],
        dormitory: [],
        department: [],

        type: [],
        brand: [],
        questionType: [],
        info: '',
        
        sendloading: false,

        rules: {
        required: value => !!value || '该项不得为空',
      },

    }),
    methods: {
        send() {
            if (this.sendloading) {
                return
            }

            var data = {
                name: this.name,
                phone: this.phone,
                wechatid: this.wechatid,
                sex: this.sex,
                dormitory: this.dormitory,
                department: this.department,

                type: this.type,
                brand: this.brand,
                questionType: this.questionType,
                info: this.info
            }

            if(checkEmptyValues(data)){
                alert('请完整填写信息')
                return
            }

            this.sendloading = true
            axios.post('https://api.gcacitia.com/home/repair/send', data)
                .then(response => {
                    this.sendloading = false
                    this.$router.push('/repairquery?showtip=1')
                })
                .catch(error => {
                    // 处理错误
                    this.sendloading = false
                    alert('发生错误，请联系微信：leoleeym')

                });
        }

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
}

.infocard {
    max-width: 400px;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>