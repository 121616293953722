<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>编程部</h1>
    </TitleModel>
    <div class="mx-auto" style="text-align: left; max-width: 1000px; padding-left: 15px; padding-right: 15px;">
        <p>编程部负责协会会员的计算机知识、编程知识培养。在培养方案上具有扩展性，发散性，在教学方式上将实行老带新和项目小组的方式，即由有经验的各组组长带领新生自主选定项目并以某种方式将成品呈现出来。并且为协会竞赛部及项目部培养人才。除此之外还需不定期面向全校师生提供相关知识的普及性讲座。
        </p>
    </div>

    <TitleModel>
        <h2>编程部部长</h2>
    </TitleModel>

    <div style="padding-left: 15px; padding-right: 15px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/林志达.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        林志达
                    </v-card-title>
                    <v-card-subtitle><strong>理事会副会长、编程部部长</strong></v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>linzhida@gcacitia.com</v-card-subtitle>
                    <v-card-text>23应用电子技术2班，原互联网科技创新协会竞赛与项目部成员，现任2024届互联网科技创新协会副会长兼编程部部长。

                        擅长软件和项目开发，精通多种编程语言和基本数据结构，包括C语言、Python、Web开发、Node.js和Flask框架等。曾开发过数字游戏趋势分析预测系统。

                        积极参与数学建模比赛、国家安全微视频大赛、电子设计创意节等活动，同时参与华为HCSD圆桌论坛、花都高校科技社团联盟交流会。在团队协作和指导方面具备丰富经验。还撰写过《一般学习法：目标学习和团队任务》一书。</v-card-text>
                </div>
            </div>
        </v-card>
    </div>

    <TitleModel>
        <h2>信息技术创新开发负责人</h2>
    </TitleModel>

    <div class="infocardbox mx-auto">
        <v-card class="infocard">
            <v-img class="mx-auto" src="../../assets/member/李佑铭.png"
                style="width: 70%; margin-top: 10%; margin-bottom: 10%;"></v-img>
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="text-h5" style="font-weight: bold;">
                        李佑铭
                    </v-card-title>
                    <v-card-text style="">
                        <strong>方向：</strong>信息技术创新开发
                        <p><strong>简介：</strong>现任互联网科技创新协会会长。华为云HCSD校园大使，华为云HCSD广州民航职业技术学院社群负责人。

                            持有阿里云人工智能助理工程师认证、工业和信息化部数学建模专业技能认证、HarmonyOS应用开发者高级认证。

                            精通Python程序设计、Web应用开发、C#程序设计、Unity游戏开发、Electron跨平台开发、ArkTS鸿蒙开发等开发框架和引擎，曾作为三个大型商业软件项目主程序参与开发。

                            曾荣获蓝桥杯大赛Python程序设计全国总决赛二等奖、全国大学生数学建模竞赛全国二等奖等5项国家级荣誉，以及20余项省部级荣誉，并发表学术论文1篇。</p>
                    </v-card-text>

                </div>
            </div>
        </v-card>


    </div>

    <TitleModel>
        <h2>编程部副部长</h2>
    </TitleModel>

    <div class="infocardbox mx-auto">
        <v-card class="infocard" v-for="(member, i) in membersinfo" :key="i">
            <v-img class="mx-auto" :src="member.photo" style="width: 70%; margin-top: 10%; margin-bottom: 10%;"></v-img>
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="text-h5" style="font-weight: bold;">
                        {{ member.name }}
                    </v-card-title>
                    <v-card-text style="">
                        <strong>方向：</strong>{{ member.fx }}
                        <p><strong>简介：</strong>{{ member.info }}</p>
                    </v-card-text>

                </div>
            </div>
        </v-card>


    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({
        membersinfo: [
            {
                name: '李晓霖',
                photo: require('../../assets/member/李晓霖.png'),
                fx: 'Python 程序设计',
                info: '2023届加入编程部，2023年通过综合考核入选竞赛部Python组。'
            },
            {
                name: '吕豪浚',
                photo: require('../../assets/member/吕豪浚.png'),
                fx: 'Python 程序设计',
                info: '2023届加入编程部，2023年通过综合考核入选竞赛部Python组。'
            },
            {
                name: '黄予政',
                photo: require('../../assets/member/黄予政.png'),
                fx: 'Python 程序设计',
                info: '2023届加入编程部，2023年通过综合考核入选竞赛部Python组。'
            },
            {
                name: '海金鹏',
                photo: require('../../assets/member/海金鹏.png'),
                fx: 'Python 程序设计',
                info: '2023届加入编程部，2023年通过综合考核入选竞赛部Python组。'
            },
            {
                name: '杜婷婷',
                photo: require('../../assets/member/杜婷婷.png'),
                fx: 'Web 应用开发',
                info: '2023届加入运营部，2023年通过综合考核入选竞赛部Python组。'
            },
            {
                name: '王爱琳',
                photo: require('../../assets/member/王爱琳.png'),
                fx: 'Web 应用开发',
                info: '2023届加入编程部。'
            }
        ]
    }),
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 300px;

}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

}
</style>