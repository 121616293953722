<template>
    <div class="mx-auto" style="max-width: 1000px; padding: 20px;">
        <v-img class="mainlogo mx-auto" style="max-width: 200px;" src="../assets/logo_text.png"></v-img>
        <TitleModel>
            <h1>互联科创下载中心</h1>
        </TitleModel>
    </div>
</template>

<script>
import TitleModel from '@/components/TitleModel.vue';

export default {

    components: {
        TitleModel,
    },

}
</script>

<style></style>