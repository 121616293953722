<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>双创部</h1>
    </TitleModel>

    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <p>双创部负责协会会员的创新创业知识的相关培训、指导参赛等。并且作为航空港管理学院创新创业大赛的协办方，还需要为学校相关活动提供支持。双创部是一个基于冲击我校创新创业大赛新高度而设立的学习、竞赛型部门。自2022年上半年伊始，现有20名核心骨干成员，不同的专业，同样的优秀。双创部作为我校创新创业大赛的协办方，双创部成员发挥着显著带头作用。在洪锐锋老师的指导下，我部全体成员共同参加了2021年广东省大学生创新创业大赛各大高校赛区，荣获校级二等奖证书、2023年广东省大学生创新创业大赛，在职教赛道荣获校级二等奖，在红旅赛道荣获校级三等奖。并在良好的学习氛围中，双创部选出三支参赛队伍，参加了为期三天的大学生数学建模比赛，分别取得了国奖、省奖的优异成绩。
        </p>
        <v-card class="mx-auto" style="margin-top: 20px;">
            <v-card-title>部长寄语</v-card-title>
            <v-card-subtitle>双创部负责人 朱海倩</v-card-subtitle>
            <v-card-text style="text-align: left;">我们要相信我们可以在平凡的生活中获得不一样的精彩。</v-card-text>

            <v-card-subtitle>双创部负责人 郭子毅</v-card-subtitle>
            <v-card-text style="text-align: left;">在青春时不留遗憾，不停奋斗吧少年 。</v-card-text>
        </v-card>
    </div>

    <TitleModel>
        <h2>双创部负责人</h2>
    </TitleModel>

    <div style="padding-left: 15px; padding-right: 15px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/朱海倩.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        朱海倩
                    </v-card-title>
                    <v-card-subtitle><strong>邮箱：</strong>zhuhaiqian@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2022届加入互联网科技创新协会双创部，2023届就任互联网科技创新协会双创部负责人。曾获2023年互联网+创新创业大赛广州民航职业技术学院校赛红旅赛道二等奖。熟练掌握Office办公套件。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/郭子毅.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        郭子毅
                    </v-card-title>
                    <v-card-subtitle><strong>邮箱：</strong>guoziyi@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2022届加入互联网科技创新协会双创部，2023届就任互联网科技创新协会双创部负责人。于2023年互联网+大学生创新创业大赛广东省分赛校赛中荣获红旅赛道三等奖，熟悉Python，Office办公套件等相关技能。</v-card-text>
                </div>
            </div>
        </v-card>

    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({

    }),
    methods: {

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.cardflex {
    display: flex;
    flex-wrap: nowrap;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>