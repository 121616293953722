<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>运营部</h1>
    </TitleModel>

    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <p>运营部利用互联网社交渠道给部门做宣传，同时负责活动的对接，策划和组织。运营部人员皆具有文案技能，能够写出吸引且符合部门特点、有价值观念和共情感的文章或视频；</p>
        <p>运营做事思维，能够制定推进计划和人员分工，与团队内外部沟通协调，为相关人员提供所需的帮助，带领团队将活动执行落地，保证每一次宣传以及活动效果，及时进行复盘；</p>
        <p>能够不断寻求改进和创新的方法；能够凡是没有规定不许做的，就可以尝试做，不受固定思维的限制，敢于突破和创新；能够将想法和计划付诸实施，不怕困难和挑战，有责任心和执行力。</p>
        <p>如果你喜欢有创意、有洞察力、有分析能力、有营销意识、有逻辑、有数据敏感度、有学习能力以及沟通协作能力，并且有一定的写作基础和内容策划能力，同时喜欢不断学习和想要积累更多的运营经验，我们欢迎你的加入!</p>

        <!-- <v-card class="mx-auto" style="margin-top: 20px;">
            <v-card-title>部长寄语</v-card-title>
            <v-card-subtitle>运营部负责人 凌佳欣</v-card-subtitle>
            <v-card-text style="text-align: left;">明日永远新鲜如初，纤尘不染。</v-card-text>
            <v-card-subtitle>运营部负责人 宋雨桐</v-card-subtitle>
            <v-card-text style="text-align: left;">你走过的路才是你的世界。</v-card-text>
        </v-card> -->
    </div>

    <TitleModel>
        <h2>运营部部长团队</h2>
    </TitleModel>

    <div style="padding-left: 15px; padding-right: 15px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/裴莹莹.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        裴莹莹
                    </v-card-title>
                    <v-card-subtitle><strong>运营部部长</strong></v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>peiyinyin@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2023届加入互联网科技创新协会运营部，2024届就任互联网科技创新协会运营部部长，曾策划、主持互联网科技创新协会周年庆等一系列协会活动。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/叶兴.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        叶兴
                    </v-card-title>
                    <v-card-subtitle><strong>运营部副部长</strong></v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>yexin@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2023届加入互联网科技创新协会运营部，2024届就任互联网科技创新协会运营部副部长，有丰富的推文制作经验，曾策划、主持互联网科技创新协会周年庆等一系列协会活动。</v-card-text>
                </div>
            </div>
        </v-card>

    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({

    }),
    methods: {

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.cardflex {
    display: flex;
    flex-wrap: nowrap;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>