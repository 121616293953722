<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <!-- <div style="text-align: center;">
        <h1 class="mx-auto">本届招新已经结束</h1>
        <h1 class="mx-auto">感谢你的支持，期待下次相遇</h1>
    </div> -->

    <v-form class="mx-auto application-form">

        <TitleModel>
            <h2>基本信息</h2>
        </TitleModel>
        <v-text-field label="姓名" v-model="name" :rules="[required]"></v-text-field>
        <v-text-field label="手机号" v-model="phone" :rules="[required]" hint="将用于通知以及面试信息查询，请务必正确填写能联系到的手机号"></v-text-field>
        <v-select label="所在院系" v-model="department"
            :items="['航空港管理学院', '民航电子信息工程学院', '民航经营管理学院', '飞机维修工程学院', '无人机学院', '空乘学院']" :rules="[required]"></v-select>
        <v-text-field label="所在班级" v-model="class_" :rules="[required]"></v-text-field>

        <TitleModel>
            <h2>纳新问卷</h2>
        </TitleModel>
        <v-select label="意向部门" v-model="intention" :items="['编程部', '运营部', '技术部', '双创部', '电子部']" :rules="[required]" multiple chips
            hint="按照第一意向、第二意向等进行先后选择" persistent-hint></v-select>
        <a :href="www" style="color: black;">
            <v-btn @click="navigateToDestination('/about')" variant="text">
                点此了解各部门
            </v-btn>
        </a>

        <div v-if="intention.indexOf('编程部') != -1">
            <TitleModel>
                <h3>编程部问卷</h3>
            </TitleModel>

            <div>是否有经验和方向不影响录取结果只是为了了解你，我们欢迎零基础的同学，我们更需要的是积极向上，愿意一起成长的同学~</div>
            <div style="margin-top: 10px;"><strong>部长寄语：</strong>不必在意别人的眼光，不要忘记自己的初心，以实际行动证明自己。</div>

            <v-textarea v-model="program_experience" style="margin-top: 20px;" label="是否有编程经验？"
                placeholder="是否有编程经验？有的话是哪种编程语言？有没有作品或者成果？若没有填否即可。" auto-grow></v-textarea>
            <v-select label="你更倾向于哪个方向？" v-model="direction" :items="['项目实践', '算法竞赛', '都可以']"></v-select>
            <v-select label="你的意向组长是？" v-model="leader" :items="['张子彬', '罗嘉堡+纪道坤', '黎幸灵+吴天航', '邱培钦', '张丹云', '黄紫馨', '郑嘉慧']"
                :rules="[required]" multiple chips hint="按照第一意向、第二意向等进行先后选择" persistent-hint></v-select>
            <a :href="www" style="color: black;">
                <v-btn @click="navigateToDestination('/about/program')" variant="text">
                    点此了解各位组长
                </v-btn>
            </a>
        </div>

        <div v-if="intention.indexOf('运营部') != -1">
            <TitleModel>
                <h3>运营部问卷</h3>
            </TitleModel>
            <div>是否有经验不影响录取结果只是为了了解你，我们欢迎零基础的同学，我们更需要的是积极向上，愿意一起成长的同学~</div>
            <v-textarea v-model="operate_experience" style="margin-top: 20px;" label="是否有运营经验？"
                placeholder="是否有推文撰写、视频剪辑、自媒体（如抖音等）、账号运营等经验？有没有作品或者成果？若没有填否即可。" auto-grow></v-textarea>
        </div>

        <div v-if="intention.indexOf('技术部') != -1">
            <TitleModel>
                <h3>技术部问卷</h3>
            </TitleModel>
            <div>是否有经验不影响录取结果只是为了了解你，我们欢迎零基础的同学，我们更需要的是积极向上，愿意一起成长的同学~</div>
            <v-textarea v-model="technology_experience" style="margin-top: 20px;" label="是否有处理/维修、电脑软硬件经验？"
                placeholder="是否有维修电脑硬件、处理软件问题、组装电脑、手机、单片机等的经验？若没有填否即可。" auto-grow></v-textarea>
        </div>

        <div v-if="intention.indexOf('双创部') != -1">
            <TitleModel>
                <h3>双创部问卷</h3>
            </TitleModel>
            <div>是否有经验不影响录取结果只是为了了解你，我们欢迎零基础的同学，我们更需要的是积极向上，愿意一起成长的同学~</div>
            <v-textarea v-model="hlwplus_experience" style="margin-top: 20px;" label="是否对互联网+创新创业大赛有所了解或经验？"
                placeholder="若没有填否即可" auto-grow></v-textarea>
            <v-textarea v-model="office_experience" label="是否熟练运用Office办公套件？"
                placeholder="可以简单说一下熟悉PPT、Excel还是Word，有什么使用经验，若没有填否即可。" auto-grow></v-textarea>
        </div>

        <v-text-field style="margin-top: 20px;" label="是否有参加其他社团/组织？加入了什么？" v-model="other_group"></v-text-field>
        <v-textarea v-model="awards" label="是否有获得过什么奖项或荣誉？" placeholder="没有的话填无即可，可以是任何类型的奖项或荣誉，有没有不影响录取，只是为了了解你~"
            auto-grow></v-textarea>
        <v-textarea v-model="selfinfo" label="简短的自我介绍" auto-grow></v-textarea>

        <div style="text-align: center;">
            <v-btn @click="send()" size="large" color="indigo-accent-4" style="font-weight: bold; width: 200px;" :loading="sendloading">提交</v-btn>
        </div>

    </v-form>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';
import axios from 'axios';

export default defineComponent({
    name: 'Home',
    components: {

        TitleModel
    },
    data: () => ({
        name: '',
        phone: '',
        department: [],
        class_: '',

        // 编程部题目
        program_experience: '',
        direction: [],
        leader: [],

        // 运营部题目
        operate_experience: '',

        // 技术部题目
        technology_experience: '',

        // 双创部题目
        hlwplus_experience: '',
        office_experience: '',

        other_group: '',
        awards: '',
        intention: [],

        selfinfo: '',

        sendloading: false
    }),
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        },
        navigateToDestination(url) {
            window.open(url, '_blank');
        },
        send() {
            this.sendloading = true
            const dataToSend = {
                name: this.name,
                phone: this.phone,
                department: this.department,
                class_: this.class_,

                // 编程部题目
                program_experience: this.program_experience,
                direction: this.direction,
                leader: this.leader,

                // 运营部题目
                operate_experience: this.operate_experience,

                // 技术部题目
                technology_experience: this.technology_experience,

                // 双创部题目
                hlwplus_experience: this.hlwplus_experience,
                office_experience: this.office_experience,

                other_group: this.other_group,
                awards: this.awards,
                intention: this.intention,

                selfinfo: this.selfinfo
            };

            axios
                .post('https://api.gcacitia.com/home/recruitment/sendApplication', dataToSend)
                .then(response => {
                    this.sendloading = false
                    this.$router.push('/recruitment/success');
                })
                .catch(error => {
                    // 处理错误
                    alert('发生错误，请联系微信：leoleeym')

                });
        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.infocardbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding: 50px;
    padding-top: 0px;
}

.infocard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.application-form {
    width: 550px;
}

@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .application-form {
        width: 90vw;
    }
}
</style>