<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>双创部</h1>
    </TitleModel>

    <div class="mx-auto" style="text-align: left; padding-left: 15px; padding-right: 15px; max-width: 1000px;">
        <p>双创部旨在攀登我校创新创业赛事的新巅峰，构筑了一个集学习与竞技于一体的独特平台。自2022年初春起，该部门汇聚了来自五湖四海的30位精英骨干，他们虽专业各异，却同样卓越非凡。作为校内创新创业大赛的重要支撑力量，双创部的成员们不仅积极参与其中，更以卓越的表现为赛事引领风尚。这里，是一个多元融合的大家庭，来自不同专业背景、怀揣共同梦想的伙伴们紧密相连。只要你怀揣信念，拥有不懈追求，双创部便敞开怀抱，欢迎你的加入，共绘辉煌篇章！
        </p>
    </div>

    <TitleModel>
        <h2>双创部部长团队</h2>
    </TitleModel>

    <div style="padding-left: 15px; padding-right: 15px;">
        <v-card class="mx-auto" style="max-width: 1000px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/许星璨.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        许星璨
                    </v-card-title>
                    <v-card-subtitle><strong>理事会副会长、双创部部长</strong></v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>xuxingcan@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2023届加入互联网科技创新协会双创部，2024届就任互联网科技创新协会理事会副会长、双创部部长。曾获2023年起航杯创新创业大赛职教赛道铜奖，曾参与组织多项活动，积极参与数学建模比赛，国家安全微视频大赛、花都高校科技社团联盟交流会等活动并有所收获。在团队协作和指导方面具备丰富经验。熟练掌握Python、
                        web前端、office三件套。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/欧阳瑶.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        欧阳瑶
                    </v-card-title>
                    <v-card-subtitle><strong>双创部副部长</strong></v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>ouyangyao@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2023届加入互联网科技创新协会双创部，2024届就任互联网科技创新协会双创部副部长。曾获2023年起航杯创新创业大赛职教赛道金奖。熟练掌握Office办公套件。</v-card-text>
                </div>
            </div>
        </v-card>

        <v-card class="mx-auto" style="max-width: 1000px; margin-top: 20px;">
            <div class="cardflex">
                <v-avatar class="ma-3" size="200" rounded="0">
                    <v-img src="../../assets/member/韩雨笑.png"></v-img>
                </v-avatar>
                <div>
                    <v-card-title class="text-h5">
                        韩雨笑
                    </v-card-title>
                    <v-card-subtitle><strong>双创部副部长</strong></v-card-subtitle>
                    <v-card-subtitle><strong>邮箱：</strong>hanyuxiao@gcacitia.com</v-card-subtitle>
                    <v-card-text>于2023届加入互联网科技创新协会双创部，2024届就任互联网科技创新协会双创部副部长。于2023年“金点子"起航杯创新创业大赛职教赛道铜奖，带领团队参加广州市反邪海报设计，创新创业大赛等，专业排名第一，熟悉掌握web前端开发，Python，Office办公三件套及Linux等相关技能。</v-card-text>
                </div>
            </div>
        </v-card>

    </div>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({

    }),
    methods: {

    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}

.cardflex {
    display: flex;
    flex-wrap: nowrap;
}


@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

    .cardflex {
        display: block;
    }

}
</style>