<template>
    <v-img class="mainlogo mx-auto" src="../../assets/logo_text.png"></v-img>
    <TitleModel>
        <h1>关于我们</h1>
    </TitleModel>

    <v-container class="cardsbox">
        <v-row align="center" justify="center">
            <v-col cols="auto" v-for="(card, i) in cards" :key="i">
                <v-card class="mx-auto" max-width="344">
                    <v-card-item>
                        <div>
                            <div class="text-h6 mb-1" style="font-weight: bold;">
                                {{ card.name }}
                            </div>
                            <div class="text-caption">{{ card.info }}</div>
                        </div>
                    </v-card-item>
                    <v-card-actions>
                        <v-btn @click="navigateToDestination(card.link)" style="font-weight: bold;">
                            查看详情
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { ref, defineComponent } from 'vue';

import TitleModel from '@/components/TitleModel.vue';

export default defineComponent({
    name: 'Home',
    components: {
        TitleModel
    },
    data: () => ({
        cards: [
            // {
            //     name: '组织架构',
            //     info: '互联网科技创新协会的整体组织架构和协会运作流程的详细介绍。',
            //     link: ''
            // },
            // {
            //     name: '协会章程',
            //     info: '保障协会全体成员在协会活动中的合法权益，确保协会有序管理的规章。',
            //     link: 'https://pan.baidu.com/s/1UgnYQijveWKHHv-2V1ABFA?pwd=gziy'
            // },
            {
                name: '理事会',
                info: '理事会是协会的常设决策机构，在全体大会闭会期间代为行使全体大会大部分职权。',
                link: '/about/council'
            },
            {
                name: '编程部',
                info: '编程部负责会员的计算机知识、编程语言的兴趣学习，并且为协会竞赛和项目部培养人才。',
                link: '/about/program'
            },
            {
                name: '运营部',
                info: '运营部负责协会的内外宣传工作、协会活动的后勤、布置、新媒体运营工作。',
                link: '/about/operation'
            },
            {
                name: '技术部',
                info: '技术部负责协会会员的硬件知识培训、讲解以及为学校、协会的相关活动提供技术支持。',
                link: '/about/technology'
            },
            {
                name: '双创部',
                info: '双创部负责协会会员思考能力，创新思维，创业能力培养、指导参赛等。',
                link: '/about/shuangchuang'
            },
            {
                name: '电子部',
                info: '电子部负责协会会员的单片机、物联网、软硬结合开发的能力培训。',
                link: '/about/dianzi'
            },
            // {
            //     name: '后台管理',
            //     info: '广州民航职业技术学院互联网科技创新协会官方网站及协会事务后台管理系统入口。',
            //     link: '/auth'
            // }
            // {
            //     name: '竞赛和项目部',
            //     info: '竞赛和项目部负责协会会员的互联网及计算机知识、编程语言、项目开发、算法设计的拔尖性培养。',
            //     link: ''
            // }
        ]
    }),
    methods: {
        required(v) {
            return !!v || '此项不得为空'
        },
        test() {
            console.log(this.intention[0])
        },
        navigateToDestination(url) {
            if(url.indexOf('http') != -1){
                window.open(url, '_blank');
            }else{
                this.$router.push(url); // 导航到目标页面
            }
            
        }
    }
});
</script>
  
<style>
.mainlogo {
    width: 10vw;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.cardsbox {
    width: 1000px;
}



@media (max-width: 991px) {
    .mainlogo {
        width: 50vw;
    }

    .cardsbox {
        width: auto;
    }

}
</style>